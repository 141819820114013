import instance from '../api';
import { message, notification } from 'antd';
import { ErrorNotification } from '../../Utils/data';

export const userCompany = {
  async read(
    id: number | string,
    companyId: undefined | number | string,
    role: undefined | string,
    driverId: undefined | string | number,
    skip: string | undefined | number = 1,
  ) {
    if (companyId === undefined) companyId = '';
    if (driverId === undefined) driverId = '';

    const { data }: any = await instance(
      `userCompanies/list?page=${skip}&perPage=10&userId=${driverId}&companyId=${companyId}&role=${role}`,
    );
    return {
      data: data.data,
      count: data.count,
    };
  },

  async addMultiUser(
    companyId: number | string | undefined,
    driverId: number | string | undefined,
    role: string,
  ) {
    try {
      message.loading({ content: 'Loading...', key: driverId });
      const { data } = await instance('/userCompanies/create', {
        method: 'POST',
        data: {
          role,
          companyId,
          userId: driverId,
        },
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key: driverId, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        notification.error({
          message: 'Error 400',
          description: error.response.data.error.message,
          style: { backgroundColor: '#FFF2F0', border: '1px solid #ffccc7' },
          duration: 3, // Установите значение 0 для того, чтобы уведомление не исчезало автоматически
        });
        const audio = new Audio('/bmw_warning.mp3');
        audio.play();

        // setTimeout(() => {
        //     message.error({content: error.response.data.error.message, duration: 3});
        // }, 1000);
        throw new Error('The current role and the role you sent are the same !');
      }
    }
  },
  async userCompanyPatch(companyData: any) {
    try {
      const key = 'updatable';
      message.loading({ content: 'Loading...', key });
      const { data } = await instance(`/usercompanies/edit/${companyData.id}`, {
        method: 'PATCH',
        data: companyData,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      ErrorNotification({
        status: error.response.status,
        message: error.response.data.error.message,
      });
    }
  },

  async userCompaniesData(userId: string | number | undefined) {
    try {
      const { data }: { data: any } = await instance(`usercompanies/find/${userId}`);
      return data;
    } catch (error: any) {
      ErrorNotification({
        status: error.response.status,
        message: error.response.data.error.message,
      });
    }
  },

  async deleteUserCompany(id: number | string) {
    message.loading({ content: 'Loading...', key: id });
    let res;
    let error = '';
    try {
      const { data } = await instance(`/userCompanies/delete/${id}`, {
        method: 'DELETE',
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Deleted!', key: id, duration: 2 });
        }, 1000);
        return u;
      });
      res = data;
    } catch (error: any) {
      ErrorNotification({
        status: error.response.status,
        message: error.response.data.error.message,
      });
    }
    return { data: res, error };
  },
  async updateMany(logData: any, ids: Array<number>) {
    try {
      message.loading({ content: 'Loading...', key: logData.id });
      const datas = new Array<any>();
      for (const id of ids) {
        await instance(`/userCompanies/edit/${id}`, {
          method: 'PATCH',
          data: logData,
        }).then((u) => {
          setTimeout(() => {
            message.success({ content: 'Loaded!', key: logData.id, duration: 2 });
          }, 1000);
          datas.push(u);
        });
      }
      return datas;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async deleteUserCompanyMany(body: Array<number> | any) {
    message.loading({ content: 'Loading...', key: body });
    let res;
    let error = '';
    try {
      const { data } = await instance(`userCompanies/delete-selected`, {
        method: 'DELETE',
        data: body,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Deleted!', key: body, duration: 2 });
        }, 1000);
        return u;
      });
      res = data;
    } catch (error: any) {
      ErrorNotification({
        status: error.response.status,
        message: error.response.data.error.message,
      });
    }
    return { data: res, error };
  },

  async getRole() {
    const { data }: any = await instance(`userCompanies/roles`);
    return {
      data: data?.data,
      count: data.count,
    };
  },
};
