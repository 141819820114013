import { Tabs } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import IftaGenerate from './IftaGenerate';

const items = [
  {
    label: <span>IFTA Logs</span>,
    key: '1',
    children: <IftaGenerate />,
    icon: <SettingOutlined rev={undefined} />,
  },
];
const Ifta = () => {
  return (
    <div className="mainPage">
      <Tabs
        type="card"
        defaultActiveKey={'1'}
        style={{ height: '100%' }}
        items={items.map((tab) => {
          return {
            label: tab.label,
            key: tab.key,
            children: tab.children,
            icon: tab.icon,
          };
        })}
      />
    </div>
  );
};

export default Ifta;
