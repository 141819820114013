import {useQuery} from "react-query";
import {vehicles} from "../../../API/TT_ELD/Users/vehicles";


export const useVehicleData = (id:string| number, companyId: string| number,vinId:string| number, truckId:string| number,vehicleId:string |number, uid:string, companyUID:string, skip: string | number = 1): object => {
    return useQuery([
            `vehicles/${companyId || 'all'}`,id, companyId, vinId,truckId,vehicleId,uid, companyUID, skip],
        () => vehicles.read(id ,companyId, vinId,truckId,vehicleId,uid, companyUID, skip),
        { refetchOnWindowFocus: false })
}

export const useVehicleCompanyData = (companyId: number | string | undefined): any => {
    return useQuery([
            `vehicles/${companyId || 'all'}`, companyId],
        () => vehicles.companyData(companyId),
        { refetchOnWindowFocus: false })
}

export const useVehicleFindCompany = async (companyId: string | number, truckId: string | number , vinId:string|number) => {
    return await vehicles.searchVehicleCompany(companyId, truckId, vinId)
}
export const useVehicleFindTruck = async (truck_number: string | number, companyId: string | number | undefined, vinId:string|number) => {
    return await vehicles.searchVehicleData(truck_number, companyId, vinId)
}
export const useVehicleFindVin = async (vin: string | number, companyId: string | number | undefined, truckId:string |number) => {
    return await vehicles.searchVehicleVinData(vin, companyId,truckId)
}
export const useVehicleFindVehicleId = async (query: string | number, valId: string | number ) => {
    return await vehicles.searchVehicleId(query,valId)
}