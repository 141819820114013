import React, { useState } from 'react';
import { Button, Modal, Spin, Table, Tag, Space } from 'antd';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import ModalExpireCompanyDate from './ModalExpireCompanyDate';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { sendEvent } from '../../../../../Utils/analytics';
import { companies } from '../../../../../API/TT_ELD/Users/company';
import { UserLocalStorage } from '../../../../interface/user/user';

const { confirm } = Modal;

interface companyDataSource {
  id: number | string;
  name: { name: string; id: string };
  owner: { ownerId: number | string | undefined | null; owners: Array<any> };
  isActive: boolean | undefined | null;
  payForDriver: boolean | undefined | null;
  stripeType: string;
  isPaid: boolean | undefined | null;
  subscriptionQuantity: number | string | undefined | null;
  disableCompanyDate: string;
  timeDifference: { createdAt: string; disableDate: string; disputedDate: string };
  subscription: string | undefined | null;
  note: string;
  disputedDate: { time: string; id: number | string; isActive: boolean };
  expireCompanyDate: { time: string; id: number | string; isActive: boolean; disableDate: string };
  action: {
    id: number | string;
    stripeCustomerId: number | string;
    stripeCustomerId2: string;
    stripeType: string;
    isActive: boolean;
  };
  key: React.Key;
}

const CompaniesTable = ({
  companiesData = [],
  total = 0,
  onChange,
  isLoading,
  isFetching,
  skip,
  refetch,
}: {
  companiesData: Array<any> | undefined;
  total: number | undefined;
  onChange(current: any): void;
  isLoading: boolean | undefined;
  isFetching: any;
  skip: number;
  refetch: any;
}) => {
  const getUserFromLocalStorage = () => {
    const userString = localStorage.getItem('user');
    try {
      const parsedUser = JSON.parse(userString || '{}');

      if (typeof parsedUser === 'object' && parsedUser !== null) {
        return parsedUser as UserLocalStorage;
      } else {
        return {} as UserLocalStorage;
      }
    } catch (e) {
      console.error('Error parsing user from localStorage', e);
      return {} as UserLocalStorage;
    }
  };
  const user = getUserFromLocalStorage();
  const proFeatures =
    user?.config?.pro_features !== null && user?.config?.pro_features !== undefined
      ? user?.config?.pro_features
      : false;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getId, setGetId] = useState<number>();
  const handleGetId = (record: any) => {
    setGetId(record);
  };
  const location = useLocation();
  let disable = location.pathname === '/disabled-companies';
  let disputed = location.pathname === '/disputed-companies';
  const showConfirmDisputed = (id: number | string, isActive: boolean) => {
    confirm({
      title: isActive ? 'Deactivate' : 'Activate',
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: `Are you sure you want to ${isActive ? 'deactivate' : 'activate'} this company?`,
      onOk: async () => {
        const newExpireDate = new Date();
        newExpireDate.setDate(newExpireDate.getDate() + 3);
        return new Promise(async (resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          await companies.companyPatchData(
            {
              is_active: isActive ? false : true,
              ...(!isActive && { disputedDate: null }),
            },
            id,
          );
          sendEvent('Expire Company Date', 'UnpaindCompanies', '', 1);
          refetch();
        }).catch(() => {});
      },
      onCancel() {},
    });
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  let disableCompanyDate = disable
    ? {
        title: 'Disabled at',
        dataIndex: 'disableCompanyDate',
        key: 'disableCompanyDate',
      }
    : {};
  let note = disable
    ? {
        title: 'Note',
        dataIndex: 'note',
        key: 'note',
      }
    : {};
  let timeDifference = disable
    ? {
        title: 'Expire Company Date',
        dataIndex: 'expireCompanyDate',
        key: 'expireCompanyDate',
        render: ({ time, id }: { time: string; id: string | number }) => {
          const now = new Date();
          const endDateTime = new Date(time);
          const timeDiffMilliseconds = endDateTime.getTime() - now.getTime();
          const timeDiffSeconds = Math.floor(timeDiffMilliseconds / 1000);
          const timeDiffMinutes = Math.floor(timeDiffSeconds / 60);
          const timeDiffHours = Math.floor(timeDiffMinutes / 60);
          const timeDiffDays = Math.floor(timeDiffHours / 24);
          const day = timeDiffDays !== 0 ? `${timeDiffDays} days.` : '';
          const hour = timeDiffHours % 24 !== 0 ? `${timeDiffHours % 24} hour.` : '';
          return (
            <div>
              {disable ? (
                <>
                  {time && (
                    <div
                      style={{ color: timeDiffDays < 0 || timeDiffHours % 24 < 0 ? '#fd3e3e' : '' }}
                    >
                      {day} {hour}
                    </div>
                  )}
                </>
              ) : (
                <>{}</>
              )}
              {disable && !time && <Button onClick={showModal}>Select expiration date</Button>}
            </div>
          );
        },
      }
    : {};
  let actionButton = disable
    ? {
        title: 'Extend Expiration Date',
        dataIndex: 'expireCompanyDate',
        key: 'expireCompanyDate',
        width: 125,
        render: ({
          time,
          id,
          isActive,
          disableDate,
        }: {
          time: string;
          id: string | number;
          isActive: boolean;
          disableDate: string;
        }) => {
          const handleConfirm = (daysToAdd: number, expireDate: string | Date) => {
            confirm({
              title: 'Activation Date',
              icon: <ExclamationCircleFilled rev={undefined} />,
              content: "Are you sure you want to prolong this company's expiration date?",
              onOk: async () => {
                const newExpireDate = new Date(expireDate);
                newExpireDate.setDate(newExpireDate.getDate() + daysToAdd);
                return new Promise(async (resolve, reject) => {
                  setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                  await companies.companyPatchData(
                    {
                      is_active: true,
                      expireCompanyDate: newExpireDate.toISOString(),
                    },
                    id,
                  );
                  sendEvent('Expire Company Date', 'UnpaidCompanies', '', 1);
                  refetch();
                }).catch(() => {
                  refetch();
                });
              },
              onCancel() {},
            });
          };
          const now = new Date();
          const endDateTime = new Date(time);
          const timeDiffMilliseconds = endDateTime.getTime() - now.getTime();
          const timeDiffDays = Math.floor(timeDiffMilliseconds / (1000 * 60 * 60 * 24));
          const timeDiffHours = Math.floor((timeDiffMilliseconds / (1000 * 60 * 60)) % 24);
          const day = timeDiffDays ? `${timeDiffDays} days.` : '';
          const hour = timeDiffHours ? `${timeDiffHours} hour.` : '';

          return (
            <div>
              {!isActive && !time && (
                <Button onClick={() => handleConfirm(3, new Date())}>Extend</Button>
              )}
              {proFeatures && isActive && !time && (
                <Button type="primary" onClick={() => handleConfirm(6, disableDate)}>
                  Extend
                </Button>
              )}
              {disable && time && (
                <div style={{ color: timeDiffDays < 0 || timeDiffHours < 0 ? '#fd3e3e' : '' }}>
                  {day} {hour}
                </div>
              )}
            </div>
          );
        },
      }
    : {};

  let disputedActions = disputed
    ? {
        title: 'Disputed Company',
        dataIndex: 'disputedDate',
        key: 'disputedDate',
        width: 125,
        render: ({
          time,
          id,
          isActive,
        }: {
          time: string;
          id: string | number;
          isActive: boolean;
        }) => {
          const showConfirm = () => {
            confirm({
              title: isActive ? 'Deactivate' : 'Activate',
              icon: <ExclamationCircleFilled rev={undefined} />,
              content: `Are you sure you want to ${
                isActive ? 'activate' : 'deactivate'
              } this company?`,
              onOk: async () => {
                const newExpireDate = new Date();
                newExpireDate.setDate(newExpireDate.getDate() + 3);
                return new Promise(async (resolve, reject) => {
                  setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                  await companies.companyPatchData(
                    {
                      is_active: isActive ? false : true,
                      isDispute: isActive ? false : true,
                    },
                    id,
                  );
                  sendEvent('Expire Company Date', 'UnpaindCompanies', '', 1);
                  refetch();
                }).catch(() => {
                  refetch();
                });
              },
              onCancel() {},
            });
          };
          return (
            <div>
              <Button onClick={showConfirm}>{isActive ? 'Deactivate' : 'Activate'}</Button>
            </div>
          );
        },
      }
    : {};
  const columns: object[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Company',
      dataIndex: 'name',
      key: 'name',
      render: ({ name, id }: { name: string; id: string }) => <Link to={`${id}`}>{name}</Link>,
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      render: ({ ownerId, owners }: { ownerId: string; owners: Array<any> }) => {
        return (
          <div>
            {disable || disputed ? (
              owners?.map((u) => <div>{u?.first_name + ' ' + u?.second_name}</div>)
            ) : (
              <div>{ownerId}</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Is Active',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (tag: boolean) => (
        <Tag color={tag ? 'geekblue' : 'red'}>{tag ? 'True' : 'False'}</Tag>
      ),
    },
    {
      title: disable ? 'Stripe Type' : 'Pay for driver',
      dataIndex: disable ? 'stripeType' : 'payForDriver',
      key: disable ? 'stripeType' : 'payForDriver',
      render: (tag: boolean | string) => {
        return (
          <>
            {disable ? (
              <>{tag}</>
            ) : (
              <Tag color={tag ? 'geekblue' : 'red'}>{tag ? 'True' : 'False'}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: 'Is Paid',
      dataIndex: 'isPaid',
      key: 'isPaid',
      render: (tag: boolean) => (
        <Tag color={tag ? 'geekblue' : 'red'}>{tag ? 'True' : 'False'}</Tag>
      ),
    },
    {
      title: 'Subscription quantity',
      dataIndex: 'subscriptionQuantity',
      key: 'subscriptionQuantity',
      width: 100,
    },
    disableCompanyDate,
    {
      title: disable ? 'Past time' : disputed ? 'Disputed data' : 'Created at',
      dataIndex: 'timeDifference',
      key: 'timeDifference',
      render: (val: { createdAt: string; disableDate: string; disputedDate: string }) => {
        const now = new Date();
        const endDateTime = new Date(val?.disableDate);
        const timeDiffMilliseconds = now.getTime() - endDateTime.getTime();
        const timeDiffSeconds = Math.floor(timeDiffMilliseconds / 1000);
        const timeDiffMinutes = Math.floor(timeDiffSeconds / 60);
        const timeDiffHours = Math.floor(timeDiffMinutes / 60);
        const timeDiffDays = Math.floor(timeDiffHours / 24);
        let time =
          (timeDiffDays <= 0 ? '' : timeDiffDays + ' days. ') +
          (timeDiffHours % 24 <= 0
            ? moment(val?.disableDate).format('YYYY-MM-DD')
            : (timeDiffHours % 24) + ' hour');
        return (
          <div>
            {disable ? (
              <>
                {val?.disableDate && (
                  <div style={{ color: timeDiffDays >= 3 ? '#fd3e3e' : 'inherit' }}>
                    {/*{timeDiffDays <= 0 ? '' :( timeDiffDays + ' days.') } {timeDiffHours % 24} hour.*/}
                    {time}
                  </div>
                )}
              </>
            ) : (
              <>{moment(disputed ? val.disputedDate : val?.createdAt).format('YYYY-MM-DD HH:mm')}</>
            )}
          </div>
        );
      },
    },
    {
      title: 'Subscription',
      dataIndex: 'subscription',
      key: 'subscription',
      render: (tag: boolean) => (
        <Tag color={tag ? 'geekblue' : 'red'}>{tag ? 'True' : 'False'}</Tag>
      ),
    },
    // timeDifference,
    actionButton,
    // disputedActions,
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: 200,
      render: ({
        id,
        stripeCustomerId,
        stripeCustomerId2,
        stripeType,
        isActive,
      }: {
        id: number | string;
        stripeCustomerId: string;
        stripeCustomerId2: string;
        stripeType: string;
        isActive: boolean;
      }) => {
        let customerId: string = '';
        if (stripeCustomerId2) {
          if (stripeType === 'stripe1') {
            customerId = stripeCustomerId;
          }
          if (stripeType === 'stripe2') {
            customerId = stripeCustomerId2;
          }
        } else {
          customerId = stripeCustomerId;
        }
        return (
          <Space>
            {disputed && (
              <Button onClick={() => showConfirmDisputed(id, isActive)}>
                {isActive ? 'Deactivate' : 'Activate'}
              </Button>
            )}
            {disable && customerId && (
              <a target={'_blank'} href={` https://dashboard.stripe.com/customers/${customerId}`}>
                <Button style={{ marginRight: 10 }} type="primary">
                  Open Stripe
                </Button>
              </a>
            )}
            <Link to={`${id}`}>
              <Button>Edit</Button>
            </Link>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <Spin size="large" spinning={isLoading || isFetching}>
        {getId && (
          <ModalExpireCompanyDate
            id={getId}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            refetch={refetch}
          />
        )}

        {companiesData && (
          <Table
            style={{ margin: '15px 0 0 0 ' }}
            onRow={(record: any) => {
              return {
                onClick: () => {
                  handleGetId(record);
                },
              };
            }}
            onChange={onChange}
            dataSource={companiesData?.map((u: any): companyDataSource => {
              const obj: companyDataSource = {
                id: u.id,
                name: { name: u?.name, id: u.id },
                owner: { ownerId: u?.ownerId, owners: u?.owners },
                isActive: u.is_active,
                payForDriver: u?.payForDriver,
                isPaid: u?.stripe?.subscription?.latest_invoice?.paid,
                stripeType: u?.stripeType,
                timeDifference: {
                  createdAt: u?.createdAt,
                  disableDate: u?.disableCompanyDate,
                  disputedDate: u?.disputedDate,
                },
                disableCompanyDate: u?.disableCompanyDate
                  ? moment(u?.disableCompanyDate).format('YYYY-MM-DD HH:mm')
                  : '',
                subscriptionQuantity: u.stripe.subscription?.quantity,
                subscription: u.stripe.subscription?.status,
                note: u.note,
                disputedDate: { time: u.disputedDate, id: u.id, isActive: u?.is_active },
                expireCompanyDate: {
                  time: u.expireCompanyDate,
                  id: u.id,
                  isActive: u?.is_active,
                  disableDate: u?.disableCompanyDate,
                },
                action: {
                  id: u.id,
                  stripeCustomerId: u?.stripeCustomerId,
                  stripeCustomerId2: u?.stripeCustomerId2,
                  stripeType: u?.stripeType,
                  isActive: u?.is_active,
                },
                key: u.id,
              };
              return obj;
            })}
            columns={columns}
            pagination={
              disable
                ? {
                    total: total,
                    current: skip,
                    showSizeChanger: false,
                  }
                : false
            }
          />
        )}
      </Spin>
    </div>
  );
};

export default CompaniesTable;
