import React from 'react';
import { useServerConfig } from '../../../../../Hooks/TT_ELD/Team/ServerConfig';
import { Button, Card, Input, Row, Space, Spin, Switch, Tabs } from 'antd';
import { Field, Form } from 'react-final-form';
import { AppleOutlined } from '@ant-design/icons';
import { serverConfig } from '../../../../../API/TT_ELD/Team/serverConfig';
import { sendEvent } from '../../../../../Utils/analytics';

type Data = {
  data?: {
    data: any;
    count: number;
  };
  isLoading?: boolean;
  refetch?: () => void;
  isFetching?: any;
};
const TabPane = Tabs.TabPane;
const { TextArea } = Input;
const ServerConfiguration = () => {
  const { data, isLoading }: Data = useServerConfig();
  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {};

  const onSubmit = async (value: any) => {
    await serverConfig.serverConfigPatch(value);
    sendEvent(
      `Changed ServerConfiguration page`,
      `Changed ServerConfiguration page `,
      `Submit ServerConfiguration page `,
      1,
    );
  };
  return (
    <div className="mainPage">
      <Card>
        <Spin size="large" spinning={isLoading}>
          {data && (
            <Form
              onSubmit={onSubmit}
              initialValues={data?.data?.config}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <Space direction="vertical" size={[50, 56]} style={{ display: 'flex' }}>
                    <Tabs defaultActiveKey="1">
                      <TabPane
                        tab={<span>Main fields</span>}
                        key="1"
                        icon={<AppleOutlined rev={undefined} />}
                      >
                        <Space direction="vertical" size={[50, 36]} style={{ display: 'flex' }}>
                          <Row style={{ margin: '20px 0 0px 0' }}>
                            <Field
                              name="disable_banner"
                              render={({ input }: { input: any }) => (
                                <Switch defaultChecked={input.value} onChange={input.onChange} />
                              )}
                            />
                            <label style={{ marginLeft: '15px' }}>Disable Banner</label>
                          </Row>
                          <Row style={{ margin: '0px 0 0px 0' }}>
                            <Field
                              name="view_message"
                              render={({ input }: { input: any }) => (
                                <Switch defaultChecked={input.value} onChange={input.onChange} />
                              )}
                            />
                            <label style={{ marginLeft: '15px' }}>View Message</label>
                          </Row>

                          <Row style={{ margin: '0px 0 0px 0', maxWidth: '500px' }}>
                            <label>Dashboard Message</label>
                            <Field
                              name="dashboard_message"
                              render={({ input, meta }: { input: any; meta: any }) => (
                                <>
                                  <TextArea
                                    rows={4}
                                    showCount
                                    maxLength={350}
                                    onChange={onChange}
                                    {...input}
                                  />
                                  {meta.error && <div style={{ color: 'red' }}>{meta.error}</div>}
                                </>
                              )}
                            />
                          </Row>

                          <Row style={{ margin: '0px 0 0px 0' }}>
                            <Field
                              name="view_message_for_app"
                              render={({ input }: { input: any }) => (
                                <Switch defaultChecked={input.value} onChange={input.onChange} />
                              )}
                            />
                            <label style={{ marginLeft: '15px' }}>View Message for App</label>
                          </Row>
                          <Row style={{ margin: '0px 0 0px 0', maxWidth: '500px' }}>
                            <label>App message</label>
                            <Field
                              name="app_message"
                              render={({ input, meta }: { input: any; meta: any }) => (
                                <>
                                  <TextArea
                                    rows={4}
                                    showCount
                                    maxLength={350}
                                    onChange={onChange}
                                    {...input}
                                  />
                                  {meta.error && <div style={{ color: 'red' }}>{meta.error}</div>}
                                </>
                              )}
                            />
                          </Row>
                          <Row gutter={[16, 40]}>
                            <div className="buttons" style={{ marginLeft: '10px' }}>
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Row>
                        </Space>
                      </TabPane>
                    </Tabs>
                  </Space>
                </form>
              )}
            />
          )}
        </Spin>
      </Card>
    </div>
  );
};

export default ServerConfiguration;
