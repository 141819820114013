import React, { useState } from 'react';
import SearchOptions from '../../../../../Utils/SearchOptions';
import { SearchResultForCompany } from '../../../../../Utils/SearchResults';
import { Button, DatePicker, Select } from 'antd';
import { useFindDriverForTMS, useLoadHistories } from '../../../../../Hooks/cleverTms/Driver';
import LoadHistorisesTable from './LoadHistorisesTable';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

type Data = {
  data?: {
    data: any;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: any;
};

const sortList = [
  {
    label: 'Asc',
    value: 'asc',
  },

  {
    label: 'Desc',
    value: 'desc',
  },
];
const statusList = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Accepted',
    value: 'accepted',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
];
const LoadHistories = () => {
  const [skip, setSkip] = useState<string | number>(1);
  const [driverId, setDriverId] = useState<string | number>('');
  const [companyId, setCompanyId] = useState<string | number>('');
  const [sort, setSort] = useState<string>('asc');
  const [status, setStatus] = useState<string>('pending');

  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date().setHours(24),
      key: 'selection',
    },
  ]);
  const { data, refetch, isLoading, isFetching }: Data = useLoadHistories(
    driverId,
    companyId,
    date,
    sort,
    status,
    skip,
  );

  const Driver = useFindDriverForTMS(companyId, '');

  const filteredDrivers = Driver?.data?.map((data: any) => ({
    label: data?.first_name + ' ' + data?.second_name,
    value: data?.id,
  }));

  const onChangeSkip = (query: any) => {
    setSkip(1 * parseInt(query.current));
  };
  const onSearch = (value: string) => {};

  return (
    <div className="mainPage">
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <SearchOptions
          SearchResult={SearchResultForCompany}
          onSelect={(value: any, { valId }: { valId: number | string }) => {
            if (valId) {
              setCompanyId(valId === undefined ? '' : valId);
              setDriverId('');
            }
          }}
          placeholder="Search by company "
          value=""
        />

        <Select
          onChange={(value, option) => {
            setDriverId(value === undefined ? '' : value);
          }}
          allowClear
          value={driverId}
          disabled={!companyId}
          placeholder="Select Divers"
          style={{ width: '300px', marginRight: '10px' }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={filteredDrivers?.map((option: any) => ({
            value: option.value,
            label: option.label,
          }))}
        />
        <RangePicker
          disabled={!companyId}
          onChange={(value: any) => {
            setDate(
              value.map((item: any) => {
                return dayjs(item).format('MM-DD-YYYY');
              }),
            );
          }}
        />

        <Select
          showSearch
          disabled={!companyId}
          style={{ width: 150, margin: '0 10px 0 10px' }}
          placeholder="Select a status"
          optionFilterProp="children"
          onChange={(value, option) => {
            setStatus(value === '' ? 'pending' : value);
          }}
          onSearch={onSearch}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={statusList?.map((option: any) => ({
            value: option.value,
            label: option.label,
          }))}
        />
        <Select
          showSearch
          disabled={!companyId}
          style={{ width: 100 }}
          placeholder="Select a sort"
          optionFilterProp="children"
          onChange={(value, option) => {
            setSort(value === '' ? 'pending' : value);
          }}
          onSearch={onSearch}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={sortList?.map((option: any) => ({
            value: option.value,
            label: option.label,
          }))}
        />
        <Button style={{ marginLeft: 'auto' }} size={'large'} onClick={refetch}>
          Refresh
        </Button>
      </span>
      <LoadHistorisesTable
        data={data?.data}
        onChange={onChangeSkip}
        total={data?.count}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </div>
  );
};

export default LoadHistories;
