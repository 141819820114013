import instance from '../api';
import { message } from 'antd';
import { ErrorNotification } from '../../Utils/data';

export const fmcsa = {
  async read(status: string, skip: string | number = 1) {
    const { data }: any = await instance(
      `logs/fmcsa-pending-list?page=${skip}&perPage=10&report_status=${status}`,
    );
    return {
      data: data.data,
      count: data.count,
    };
  },

  async fmcsaPatch(id: any, body: any) {
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });
    try {
      const { data }: { data: any } = await instance(`logs/fmcsa-edit/${id}`, {
        method: 'PATCH',
        data: body,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async fmcsaChech(id: any, body: any) {
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });
    try {
      const { data }: { data: any } = await instance(`webservices/fmcsa-update-status/${id}`, {
        method: 'PATCH',
        data: body,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async fmcsaEditStatus(id: any, body: any) {
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });
    try {
      const { data }: { data: any } = await instance(`logs/fmcsa-edit/${id}`, {
        method: 'PATCH',
        data: body,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async checkFmcsaPost(id: any) {
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });
    try {
      const { data }: { data: any } = await instance(`logs/audit/${id}`, {
        method: 'POST',
        // data: body
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },

  async fmcsaCount() {
    const isAuthenticated = localStorage.getItem('token') as string;
    if (isAuthenticated) {
      const { data }: any = await instance(`logs/fmcsa-count`);
      return {
        data: data.data,
        count: data.count,
      };
    }
  },
};
