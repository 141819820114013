import React, { useCallback, useState } from 'react';
import { useLogsData } from '../../../../../../Hooks/TT_ELD/Logs';
import moment from 'moment';
import { Button, DatePicker, Select, Space } from 'antd';
import LogsTable from './LogsTable';
import { logs } from '../../../../../../API/TT_ELD/logs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useUserData } from '../../../../../../Hooks/TT_ELD/Users';
import { sendEvent } from '../../../../../../Utils/analytics';
import OpenNewDay from './OpenNewDay';

const { Option } = Select;

interface Params {
  id: string | undefined;

  [key: string]: string | undefined;
}

type Data = {
  data?: {
    data: Array<any>;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: any;
};

const recordOptions = [
  { value: 0, label: '0 - (Original)' },
  { value: 1, label: '1 - (Active)' },
  { value: 2, label: '2 - (Inactive changed)' },
  { value: 3, label: '3 - (Inactive requested)' },
  { value: 5, label: '5 - (Inactive offline)' },
  { value: 20, label: '20 - (Inactive changed)' },
  { value: 40, label: '40 - (Inactive exchanged)' },
  { value: 70, label: '70 - (Cron malfunction)' },
  { value: 74, label: '74 - (App malfunction)' },
  { value: 150, label: '150 - (Offline)' },
];
function Logs() {
  const { id } = useParams<Params>();
  const data2 = useUserData(id);
  const [openDay, setOpenDay] = useState(false);
  const [recordStatus, setRecordStatus] = useState<number>(1);
  const [todayDate, setTodayDate] = useState<string>(
    moment.utc(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').format('DD-MM-YYYY'),
  );
  const location = useLocation();
  let navigate = useNavigate();
  const locationDay = location.search;
  const dateRegex = /(\d{2}-\d{2}-\d{4})/;
  const match = locationDay.match(dateRegex);
  let date: any;
  if (match) {
    date = match[1];
  }

  const { data, refetch, isLoading, isFetching }: Data = useLogsData(
    id,
    date ? date : todayDate,
    recordStatus,
  );
  const dateStr: any = date;
  const dateFormat = 'DD-MM-YYYY';
  const dateObj = dayjs(dateStr, dateFormat);
  const defaultValue: any = dayjs();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  let array: any = data;
  const reversedArray = [];

  for (let i = array?.length - 1; i >= 0; i--) {
    reversedArray.push(array[i]);
  }
  const showModal = () => {
    setOpenDay(true);
  };
  const changeDate = useCallback((date: string) => {
    setTodayDate(date);
  }, []);
  const datePicker = (date: any, dateString: any) => {
    changeDate(moment.utc(dateString, 'DD-MM-YYYY').format('DD-MM-YYYY'));
    navigate(
      `/user/users/${id}?tabKey=4/?logs=${dateString}/?record=${
        recordStatus ? recordStatus : '1'
      } `,
    );
    sendEvent('Logs Calendar', 'Logs Calendar', ` Logs tab Calendar `, 1);
  };

  const onSubmit = async (value: any) => {
    await logs.cleanCache(id, todayDate);
    sendEvent(
      'Logs tab Clean Cache Button',
      'Logs tab Clean Cache Button',
      ` Logs tab Clean Cache Button`,
      1,
    );
    refetch();
  };
  const handleChange = (value: any) => {
    setRecordStatus(value);
    navigate(`/user/users/${id}?tabKey=4/?logs=${date ? date : todayDate}/?record=${value}`);
    sendEvent('Logs Tab Record_Status', 'Logs Tab Record_Status', 'Logs Record_Status', 1);
  };
  return (
    <div>
      <Space align="baseline" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <DatePicker
            defaultValue={date ? dayjs(dateObj) : defaultValue}
            format={'DD-MM-YYYY'}
            disabledDate={(current) => {
              let customDate = moment().add(1, 'd').format('DD-MM-YYYY');
              return current && current > moment(customDate, 'DD-MM-YYYY');
            }}
            onChange={datePicker}
            clearIcon
          />
          <Select
            showSearch
            placeholder="Select a status"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={recordOptions}
            defaultValue={recordStatus}
            onChange={handleChange}
            style={{ width: 200 }}
          />
          {Number(recordStatus) === 1 && (
            <Button onClick={showModal} style={{ marginLeft: '15px' }} type="primary">
              Open New Day
            </Button>
          )}
          {openDay && (
            <OpenNewDay open={openDay} setOpen={setOpenDay} refetch={refetch} driverId={id} />
          )}
          <Button
            onClick={onSubmit}
            type="primary"
            style={{
              backgroundColor: 'rgb(89, 90, 212)',
              borderColor: 'rgb(89, 90, 212) ',
              marginLeft: '20px',
            }}
          >
            Clean Cache
          </Button>
        </div>

        <div>
          <Button size={'large'} onClick={refetch}>
            Refresh
          </Button>

          {isModalVisible && (
            <Button
              style={{ marginLeft: '15px' }}
              size="large"
              key="1"
              type="primary"
              onClick={() => setOpen(!open)}
            >
              Bulk Edit
            </Button>
          )}
        </div>
      </Space>

      <LogsTable
        logs={reversedArray}
        userId={id}
        data2={data2}
        recordStatus={recordStatus}
        isLoading={isLoading}
        isFetching={isFetching}
        refetch={refetch}
        setIsModalVisible={setIsModalVisible}
        open={open}
        setOpen={setOpen}
        toDayDate={todayDate}
      />
    </div>
  );
}

export default Logs;
