import React from 'react';
import { Modal, Tag } from 'antd';
import { Badge, Descriptions } from 'antd';

interface MyData {
  id?: number | string;
  company: { id: numStr; name: numStr };
  driver: { id: numStr; first_name: numStr; second_name: numStr };
  companyId: numStr;
  createdAt: numStr;
  driverId: numStr;
  end_date: numStr;
  filecode: numStr;
  link: numStr;
  name: numStr;
  origin: numStr;
  processedTime: numStr;
  start_date: numStr;
  status: numStr;
  submissionId: numStr;
  type: numStr;
}

interface MyComponentProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  data: MyData | undefined | any;
}

type numStr = string | number;

const FmcsaAllInfo: React.FC<MyComponentProps> = ({
  data,
  isModalVisible,
  setIsModalVisible,
}: {
  data: MyData | any;
  isModalVisible: boolean | undefined;
  setIsModalVisible(isModalVisible: boolean): void;
}) => {
  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };
  return (
    <Modal
      width={1200}
      title="All Info FMCSA"
      open={isModalVisible}
      footer=""
      onCancel={handleCancel}
    >
      <Descriptions bordered>
        <Descriptions.Item label="Company ID">{data?.companyId}</Descriptions.Item>
        <Descriptions.Item label="Driver ID">{data?.driverId}</Descriptions.Item>
        <Descriptions.Item label="ID" span={2}>
          <Badge status="processing" text={data?.id} />
        </Descriptions.Item>
        <Descriptions.Item label="Created at">{data?.createdAt}</Descriptions.Item>
        <Descriptions.Item label="End date">{data?.end_date}</Descriptions.Item>
        <Descriptions.Item label="Processed Time">{data?.processedTime}</Descriptions.Item>
        <Descriptions.Item label="Start Date">{data?.start_date?.startDate}</Descriptions.Item>
        <Descriptions.Item label="Filecode" span={1}>
          {data?.filecode}
        </Descriptions.Item>

        <Descriptions.Item label="Link">{data?.link}</Descriptions.Item>
        <Descriptions.Item label="Name">{data?.name}</Descriptions.Item>
        <Descriptions.Item label="Origin">{data?.origin}</Descriptions.Item>
        <Descriptions.Item label="Status">{data?.status}</Descriptions.Item>
        <Descriptions.Item label="Submission ID">{data?.submissionId}</Descriptions.Item>
        <Descriptions.Item label="Type">
          {data?.type === 'outputfile' ? (
            <Tag color="green">OUTPUTFILE</Tag>
          ) : (
            <Tag color="geekblue">OUTPUT</Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Company">
          ID : {data?.company?.id}
          <br />
          Name : {data?.company?.name}
          <br />
        </Descriptions.Item>
        <Descriptions.Item label="Driver">
          ID : {data?.driver?.id}
          <br />
          First Name : {data?.driver?.first_name}
          <br />
          Second Name : {data?.driver?.second_name}
          <br />
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default FmcsaAllInfo;
