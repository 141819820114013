import instanceTMS from '../apiClevertms';

export const unitsController = {
  async read(skip: string | number, date: any) {
    let url =
      (date && !date) || !date[0] || !date[1]
        ? `admin/report/unit?page=${skip}&limit=10`
        : `admin/report/unit?page=${skip}&limit=10&fromDate=${date[0]}&toDate=${date[1]}`;
    const { data }: { data: any } = await instanceTMS(`${url}`);
    return {
      data: data?.data,
      count: data.count,
    };
  },
  async getCompany(companyId: number | undefined, skip: string | number, unit: string, date: any) {
    if (unit === undefined) unit = '';
    if (companyId) {
      let url =
        (date && !date) || !date[0] || !date[1]
          ? `admin/report/unit/${companyId}?page=${skip}&search=${unit}&limit=10`
          : `admin/report/unit/${companyId}?page=${skip}&search=${unit}&limit=10&fromDate=${date[0]}&toDate=${date[1]}`;
      const { data }: { data: any } = await instanceTMS(`${url}`);
      return {
        data: data?.data,
        count: data.total,
      };
    }
  },

  async unitsFinder(unitNum: any, company: number | string) {
    const { data }: any = await instanceTMS(`admin/report/unit/${company}?search=${unitNum}`);
    return data?.data;
  },
};
