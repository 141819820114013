import React from 'react';
import { Modal, Tag } from 'antd';
import { Badge, Descriptions } from 'antd';

type numStr = string | number;
interface MyData {
  additionalData: { usdot: numStr; company_name: numStr };
  first_name: numStr;
  second_name: numStr;
  email: numStr;
  createdAt: numStr;
  emailVerified: numStr;
  is_active: boolean;
  phone: numStr;
  registerStatus: numStr;
  role: numStr;
  id: numStr;
}

interface MyComponentProps {
  data: MyData | undefined;
  isModalVisible: boolean | undefined;
  setIsModalVisible(isModalVisible: boolean): void;
  text: string;
}
const ApprovalAllInfo: React.FC<MyComponentProps> = ({
  data,
  isModalVisible,
  setIsModalVisible,
  text,
}: {
  data: MyData | undefined;
  isModalVisible: boolean | undefined;
  setIsModalVisible(isModalVisible: boolean): void;
  text: string;
}) => {
  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };
  return (
    <Modal width={1200} title={text} open={isModalVisible} footer="" onCancel={handleCancel}>
      <Descriptions bordered>
        <Descriptions.Item label="First Name">{data?.first_name}</Descriptions.Item>
        <Descriptions.Item label="Second Name">{data?.second_name}</Descriptions.Item>
        <Descriptions.Item label="Email">{data?.email}</Descriptions.Item>
        <Descriptions.Item label="ID" span={1}>
          <Badge status="processing" text={data?.id} />
        </Descriptions.Item>

        <Descriptions.Item label="Created At">{data?.createdAt}</Descriptions.Item>
        <Descriptions.Item label="Email Verified">
          {
            <Tag color={data?.emailVerified ? 'geekblue' : 'red'}>
              {data?.is_active ? 'True' : 'False'}
            </Tag>
          }
        </Descriptions.Item>
        <Descriptions.Item label="Is Active">
          {
            <Tag color={data?.is_active ? 'geekblue' : 'red'}>
              {data?.is_active ? 'True' : 'False'}
            </Tag>
          }
        </Descriptions.Item>
        <Descriptions.Item label="Phone number" span={1}>
          {data?.phone}
        </Descriptions.Item>

        <Descriptions.Item label="Register Status">{data?.registerStatus}</Descriptions.Item>
        <Descriptions.Item label="Role">{data?.role}</Descriptions.Item>
        <Descriptions.Item label="Additional Data">
          USDOT : {data?.additionalData?.usdot}
          <br />
          Company : {data?.additionalData?.company_name}
          <br />
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default ApprovalAllInfo;
