import React, { useMemo, useState } from 'react';
import {
  certifyStatus,
  configNumberInputs,
  configs,
  coordinatesSource,
  inputNumber,
} from '../../../../../../Utils/data';
import {
  Input,
  Select,
  Space,
  Switch,
  Button,
  Spin,
  InputNumber,
  Row,
  Col,
  Card,
  Tooltip,
  Flex,
  Divider,
} from 'antd';
import { useUserData } from '../../../../../../Hooks/TT_ELD/Users';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { users } from '../../../../../../API/TT_ELD/Users/user';
import { sendEvent } from '../../../../../../Utils/analytics';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
type MyObjectType = {
  [key: string | number]: any;
};

interface Params {
  id: string | undefined;

  [key: string]: string | undefined;
}

type Data = {
  data: any;
  isLoading?: boolean;
  refetch(): void;
};
const Config = () => {
  const CustomTooltipContent = ({ html }: { html: any }) => (
    <div dangerouslySetInnerHTML={{ __html: html }} />
  );
  const { id } = useParams<Params>();
  const { data, isLoading, refetch }: Data = useUserData(id);
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const [arrow, setArrow] = useState('Show');
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  const getCommand = async (command: string) => {
    await users.command(data.id, command);
    enterLoading(1);
    refetch();
  };

  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const onSubmit = async (values: any) => {
    const body: MyObjectType = {};
    let key: string | number;
    let key2: string | number;
    for (key in values) {
      if (typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] != null) {
        for (key2 in values[key]) {
          if (data[key][key2] != values[key][key2]) {
            body[key] = values[key];
            break;
          }
        }
      } else if (data[key] != values[key]) {
        body[key] = values[key];
      }
    }
    await users.userDataPatch(id, body);
    sendEvent('Config Tab ', `Config ${values} `, `Config Click `, 1);
    refetch();
  };

  return data ? (
    <Card>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              {data?.role === 'dispatcher' && (
                <div className="config_tabs">
                  {/*Pro features*/}
                  <Card style={{ width: '330px' }}>
                    <Flex gap="middle" vertical>
                      <Divider orientation="left">Pro features</Divider>
                      {configs
                        .filter((config) => config.group === 'pro_features')
                        .map((config, index) => {
                          return (
                            data?.role === config.case && (
                              <Col key={index}>
                                <Field
                                  name={`config.${config.name}`}
                                  render={({ input }) => (
                                    <Switch
                                      defaultChecked={input.value || config?.defaultChecked}
                                      onChange={input.onChange}
                                    />
                                  )}
                                />
                                <label style={{ marginLeft: '15px' }}>
                                  {config.label}
                                  {config?.title && (
                                    <Tooltip
                                      placement="rightTop"
                                      title={<CustomTooltipContent html={config?.title} />}
                                      arrow={mergedArrow}
                                    >
                                      <InfoCircleOutlined
                                        style={{ marginLeft: '15px', cursor: 'pointer' }}
                                      />
                                    </Tooltip>
                                  )}
                                </label>
                              </Col>
                            )
                          );
                        })}
                    </Flex>
                  </Card>
                  {/* Sidebar Menu Pages */}
                  <Card style={{ width: '330px' }}>
                    <Flex gap="middle" vertical>
                      <Divider orientation="left">Pages</Divider>
                      {configs
                        .filter((config) => config.group === 'sidebarMenuPages')
                        .map((config, index) => {
                          return (
                            data?.role === config.case && (
                              <Col key={index}>
                                <Field
                                  name={`config.${config.name}`}
                                  render={({ input }) => (
                                    <Switch
                                      defaultChecked={input.value || config?.defaultChecked}
                                      onChange={input.onChange}
                                    />
                                  )}
                                />
                                <label style={{ marginLeft: '15px' }}>
                                  {config.label}
                                  {config?.title && (
                                    <Tooltip
                                      placement="rightTop"
                                      title={<CustomTooltipContent html={config?.title} />}
                                      arrow={mergedArrow}
                                    >
                                      <InfoCircleOutlined
                                        style={{ marginLeft: '15px', cursor: 'pointer' }}
                                      />
                                    </Tooltip>
                                  )}
                                </label>
                              </Col>
                            )
                          );
                        })}
                    </Flex>
                  </Card>
                  {/* Reports */}
                  <Card style={{ width: '330px' }}>
                    <Flex gap="middle" vertical>
                      <Divider orientation="left">Reports</Divider>
                      {configs
                        .filter((config) => config.group === 'reports')
                        .map((config, index) => {
                          return (
                            data?.role === config.case && (
                              <Col key={index}>
                                <Field
                                  name={`config.${config.name}`}
                                  render={({ input }) => (
                                    <Switch
                                      defaultChecked={input.value || config?.defaultChecked}
                                      onChange={input.onChange}
                                    />
                                  )}
                                />
                                <label style={{ marginLeft: '15px' }}>
                                  {config.label}
                                  {config?.title && (
                                    <Tooltip
                                      placement="rightTop"
                                      title={<CustomTooltipContent html={config?.title} />}
                                      arrow={mergedArrow}
                                    >
                                      <InfoCircleOutlined
                                        style={{ marginLeft: '15px', cursor: 'pointer' }}
                                      />
                                    </Tooltip>
                                  )}
                                </label>
                              </Col>
                            )
                          );
                        })}
                    </Flex>
                  </Card>
                </div>
              )}
              <Row gutter={[16, 16]}>
                {configs
                  .filter((config) => config.group === 'driver')
                  .map((config, index) => {
                    return (
                      data?.role === config.case && (
                        <Col span={8} key={index}>
                          <Field
                            name={`config.${config.name}`}
                            render={({ input }) => (
                              <Switch
                                defaultChecked={input.value || config?.defaultChecked}
                                onChange={input.onChange}
                              />
                            )}
                          />
                          <label style={{ marginLeft: '15px' }}>
                            {config.label}
                            {config?.title && (
                              <Tooltip
                                placement="rightTop"
                                title={<CustomTooltipContent html={config?.title} />}
                                arrow={mergedArrow}
                              >
                                <InfoCircleOutlined
                                  style={{ marginLeft: '15px', cursor: 'pointer' }}
                                />
                              </Tooltip>
                            )}
                          </label>
                        </Col>
                      )
                    );
                  })}
              </Row>
              {data?.role !== 'dispatcher' && (
                <>
                  <Row gutter={[16, 16]}>
                    {inputNumber.map((u, index) => {
                      return (
                        <Col span={8}>
                          <div>
                            <label>{u.label}</label>
                            <Field
                              name={`config.${u.name}`}
                              render={({ input }: { input: any }) => {
                                const defaultValue =
                                  input?.value == null ? input?.value : u.defaultValue;
                                return (
                                  <InputNumber
                                    title={u.title}
                                    style={{ width: '100%' }}
                                    placeholder={u.label}
                                    {...input}
                                    defaultValue={defaultValue}
                                  />
                                );
                              }}
                            />
                          </div>
                        </Col>
                      );
                    })}
                    <Col span={8}>
                      <div>
                        <label>Certify status</label>
                        <Field
                          name="config.certify_status"
                          render={({ input }: { input: any }) => (
                            <Select
                              defaultValue={input.value}
                              onChange={(value, option) => {
                                input.onChange(value);
                              }}
                              style={{ width: '100%' }}
                            >
                              {certifyStatus.map((data, index) => (
                                <Option key={data.id}>{data.name}</Option>
                              ))}
                            </Select>
                          )}
                        />
                      </div>
                    </Col>
                    <Col span={8}>
                      <div>
                        <label>Coordinates source</label>
                        <Field
                          name="config.coordinates_source"
                          render={({ input }: { input: any }) => (
                            <Select
                              defaultValue={input.value}
                              onChange={(value, option) => {
                                input.onChange(value);
                              }}
                              style={{ width: '100%' }}
                            >
                              {coordinatesSource.map((data, index) => (
                                <Option key={data.id}>{data.name}</Option>
                              ))}
                            </Select>
                          )}
                        />
                      </div>
                    </Col>
                    <Col span={8}>
                      <div>
                        <label>Tracking source</label>
                        <Field
                          name="config.tracking_source"
                          render={({ input }: { input: any }) => (
                            <Select
                              defaultValue={input.value}
                              onChange={(value, option) => {
                                input.onChange(value);
                              }}
                              style={{ width: '100%' }}
                            >
                              {coordinatesSource.map((data) => (
                                <Option key={data.id}>{data.name}</Option>
                              ))}
                            </Select>
                          )}
                        />
                      </div>
                    </Col>
                    {configNumberInputs.map((u, index) => {
                      return (
                        <Col span={8} key={index}>
                          <label>{u.label}: </label>
                          <Field
                            name={u.name}
                            render={({ input }: { input: any }) => (
                              <Input placeholder={u.label} type="number" {...input} />
                            )}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}

              <div style={{ display: 'flex', marginTop: 20 }}>
                <Button
                  style={{ marginRight: 15 }}
                  type="primary"
                  htmlType="submit"
                  loading={loadings[0]}
                  onClick={() => enterLoading(0)}
                >
                  Submit
                </Button>
                {data.role === 'driver' && (
                  <Button
                    loading={loadings[1]}
                    type="primary"
                    onClick={() => getCommand('update_user_info')}
                  >
                    UPDATE USER INFO
                  </Button>
                )}
              </div>
            </Space>
          </form>
        )}
      />
    </Card>
  ) : (
    <Spin size="large" spinning={isLoading}></Spin>
  );
};

export default Config;
