import React, { useState } from 'react';
import SearchOptions from '../../../../../Utils/SearchOptions';
import { SearchResultForCompany } from '../../../../../Utils/SearchResults';
import { Button, Select } from 'antd';
import { useFindDriverForTMS, useNote } from '../../../../../Hooks/cleverTms/Driver';
import NoteTable from './NoteTable';

type Data = {
  data?: {
    data: any;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: any;
};
const Note = () => {
  const [skip, setSkip] = useState<string | number>(1);
  const [driverId, setDriverId] = useState<string | number>('');
  const [companyId, setCompanyId] = useState<string | number>('');

  const { data, refetch, isLoading, isFetching }: Data = useNote(driverId, companyId, skip);

  const Driver = useFindDriverForTMS(companyId, '');
  const filteredDrivers = Driver?.data?.map((data: any) => ({
    label: data?.first_name + ' ' + data?.second_name,
    value: data?.id,
  }));

  const onChange = (query: any) => {
    setSkip(1 * parseInt(query.current));
  };
  return (
    <div className="mainPage">
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <SearchOptions
          SearchResult={SearchResultForCompany}
          onSelect={(value: any, { valId }: { valId: number | string }) => {
            if (valId) {
              setCompanyId(valId === undefined ? '' : valId);
              setDriverId('');
            }
          }}
          placeholder="Search by company"
          value={''}
        />

        <Select
          onChange={(value, option) => {
            setDriverId(value === undefined ? '' : value);
          }}
          allowClear
          value={driverId}
          disabled={!companyId}
          placeholder="Select Divers"
          style={{ width: '300px' }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={filteredDrivers?.map((option: any) => ({
            value: option.value,
            label: option.label,
          }))}
        />
        <Button style={{ marginLeft: 'auto' }} size={'large'} onClick={refetch}>
          Refresh
        </Button>
      </span>
      <NoteTable
        data={data?.data}
        onChange={onChange}
        total={data?.count}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </div>
  );
};

export default Note;
