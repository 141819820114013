import instanceTMS from '../../apiClevertms';
import { notification } from 'antd';

export const appDrivers = {
  async read(driverId: string | number, companyid: string | number, skip: string | number = 1) {
    const { data }: any = await instanceTMS(
      `admin/driver/active-loads?page=${skip}&perPage=10000&driverId=${driverId}&companyId=${companyid}`,
    );
    return {
      data: data,
      count: data.length,
    };
  },

  async loadHistories(
    driverId: string | number,
    companyid: string | number,
    date: { startDate: Date; endDate: number; key: string }[],
    sort: string,
    status: string,
    skip: string | number = 1,
  ) {
    let url =
      (date && !date) || !date[0] || !date[1]
        ? `admin/driver/load-histories?page=1&perPage=10&sort=${sort}&status=${status}&driverId=${driverId}&companyId=${companyid}`
        : `admin/driver/load-histories?page=1&perPage=10&sort=${sort}&status=${status}&fromDate=${date[0]}&endDate=${date[1]}&driverId=${driverId}&companyId=${companyid}`;
    const { data }: { data: [] } = await instanceTMS(`${url}`);
    return {
      data: data,
      count: data.length,
    };
  },

  async loadFindOne(id: string | number | undefined) {
    try {
      const { data }: { data: any } = await instanceTMS(`admin/driver/load-history/${id}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        notification.error({
          message: 'Error 404',
          description: error.response.data.error.message,
          style: { backgroundColor: '#FFF2F0', border: '1px solid #ffccc7' },
          duration: 3,
        });
      }
    }
  },

  async loadFiles(
    driverId: string | number,
    companyid: string | number,
    skip: string | number = 1,
  ) {
    const { data }: any = await instanceTMS(
      `admin/driver/loadfiles?driverId=${driverId}&companyId=${companyid}&loadId=`,
    );
    return {
      data: data,
      count: data.length,
    };
  },
  async driverNote(
    driverId: string | number,
    companyid: string | number,
    skip: string | number = 1,
  ) {
    const { data }: any = await instanceTMS(
      `admin/driver/note?page=1&perPage=10&search=''&driverId=${driverId}&companyId=${companyid}&loadId=q`,
    );
    return {
      data: data,
      count: data.length,
    };
  },

  async salaryList(
    driverId: string | number,
    companyid: string | number,
    skip: string | number = 1,
  ) {
    const { data }: any = await instanceTMS(
      `admin/driver/salary/list?companyId=${companyid}&driverId=${driverId}`,
    );
    return {
      data: data,
      count: data.length,
    };
  },
};
