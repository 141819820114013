import instance from '../../api';
import { message } from 'antd';
import apiRead from '../../apiRead';
import { ErrorNotification } from '../../../Utils/data';

export const users = {
  async read(
    companyId: undefined | number | string,
    role: undefined | string,
    status: boolean | null | string,
    driverName: undefined | string | number,
    driverEmail: undefined | string | number,
    userName: undefined | string | number,
    licenseNumber: undefined | string | number,
    uid: string,
    companyUID: string,
    skip: string | number = 0,
  ) {
    let cmp = '';
    if (companyId === undefined) companyId = '';
    if (role === undefined) {
      role = cmp;
    }
    if (driverName || driverEmail || userName || status) {
      skip = 1;
    }
    let url =
      status && status !== 'null'
        ? `users/searching-list?page=${skip}&perPage=10&companyId=${companyId}&role=${role}&status=${status}&searchName=${driverName}&searchEmail=${driverEmail}&searchUserName=${userName}&searchLicenseNumber=${licenseNumber}&uid=${uid}&companyUid=${companyUID}`
        : `users/searching-list?page=${skip}&perPage=10&companyId=${companyId}&role=${role}&searchName=${driverName}&searchEmail=${driverEmail}&searchUserName=${userName}&searchLicenseNumber=${licenseNumber}&uid=${uid}&companyUid=${companyUID}`;

    const { data }: any = await instance({ url });
    return {
      data: data.data,
      count: data.count,
    };
  },
  async userData(userId: string | number | undefined) {
    try {
      if (userId) {
        const { data }: { data: any } = await instance(`users/find/${userId}`);
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async userDataPatch(userId: string | number | undefined, userData: any) {
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });
    try {
      userData.companyId = undefined;
      const { data }: { data: any } = await instance(`users/edit-user/${userId}`, {
        method: 'PATCH',
        data: userData,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async command(userId: string | number | undefined, command: string) {
    try {
      const { data }: { data: any } = await instance(`drivers/command`, {
        method: 'POST',
        data: {
          userId,
          command,
        },
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key: userId, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async changeUserPassword(password: string | number | undefined, id: number) {
    const { data }: { data: any } = await instance(`dashboards/changeuserpass/${id}`, {
      method: 'POST',
      data: {
        password,
      },
    });
    return data;
  },
  async freeTrial(values: number | string, id: string | number) {
    message.loading({ content: 'Loading...', key: id });
    let res;
    let error = '';
    try {
      const { data } = await instance(`stripes/free-trial/${id}`, {
        method: 'POST',
        data: values,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key: id, duration: 2 });
        }, 1000);
        return u;
      });
      res = data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
    return { data: res, error };
  },
  async newReportV3(body: any, zeroVehicle: boolean, companyId: any) {
    message.loading({ content: 'Loading...', key: body });
    try {
      const { data } = await apiRead(
        `dashboards/v3/selectedstate/log?zeroVehicle=${zeroVehicle}&companyId=${companyId}`,
        {
          method: 'POST',
          data: body,
        },
      ).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key: body, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async killToken(id: string | undefined) {
    message.loading({ content: 'Loading...', key: id });
    try {
      const { data } = await instance(`users/kill/token?driverId=${id}`, {
        method: 'POST',
        data: id,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key: id, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },

  async trackingByRang(skip: string | number, date: any, driverId: string | undefined) {
    try {
      let url = `admins/get-trackings/${driverId}?from=${date.startDate}&to=${date.endDate}`;
      if (date.startDate && date.endDate) {
        const { data }: { data: any } = await instance(`${url}`);
        return {
          data: data,
          count: data.length,
        };
      }
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },

  async fmcsaDriver(
    driverId: string | undefined,
    skip: string | number,
    date: any,
    uid: string,
    values: string,
  ) {
    try {
      if (date && date[0] && date[1]) {
        let url = `logs/daily/inspection/${driverId}/email?startDate=${date[0]}&endDate=${date[1]}&companyUid=${uid}`;
        const { data }: { data: any } = await instance(`${url}`, {
          method: 'POST',
          data: values,
        });
        return {
          data: data?.data,
          count: data.count,
        };
      }
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
};
