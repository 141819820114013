import React, { useState } from 'react';
import { Button, Card, Col, Form as FormAnt, Row, Select } from 'antd';
import { DatePicker, Space } from 'antd';
import { useParams } from 'react-router-dom';
import { useVehicleSearch } from '../../../../../../../../Hooks/TT_ELD/Logs';
import { companies } from '../../../../../../../../API/TT_ELD/Users/company';

interface Params {
  [key: string]: string | undefined;

  id: string | undefined;
}

const IftaGenerate = () => {
  const [form] = FormAnt.useForm();
  const { id } = useParams<Params>();
  const vehicleData: any = useVehicleSearch('', id);
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };
  // const onChange: DatePickerProps['onChange'] = (date, dateString) => {
  //     if (date) {
  //         const YYYY = date.year();
  //         const month = (date.month() + 1).toString().padStart(2, '0');
  //     } else {
  //         console.log('No date selected');
  //     }
  // };
  const onSubmit = async (value: any) => {
    if (value.vehicles) {
      const YYYY = value.date.year();
      const month = (value.date.month() + 1).toString().padStart(2, '0');
      console.log(YYYY, month, value.vehicles);
      let body = {
        year: YYYY,
        month: month,
        companyId: id,
        vehicleId: value.vehicles,
      };
      await companies.regenerate(body);
      // console.log(body,'body')
    } else {
      console.log('No date selected');
    }
  };
  return (
    <Card>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <FormAnt
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'public' }}
          onFinish={onSubmit}
        >
          <Row gutter={16}>
            <Col span={8}>
              <FormAnt.Item
                name="date"
                label="Date range"
                rules={[{ required: true, message: 'Please enter your date!' }]}
              >
                <DatePicker picker="month" />
              </FormAnt.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <FormAnt.Item
                name="vehicles"
                label="Vehicles"
                rules={[{ required: true, message: 'Please enter your vehicles!' }]}
              >
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  filterOption={(input, option: any) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={vehicleData?.data?.data?.map((option: any) => ({
                    value: option.id,
                    label: option.truck_number,
                  }))}
                />
              </FormAnt.Item>
            </Col>
          </Row>

          <FormAnt.Item>
            <Button
              onClick={() => enterLoading(1)}
              loading={loadings[1]}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </FormAnt.Item>
        </FormAnt>
      </Space>
    </Card>
  );
};

export default IftaGenerate;
