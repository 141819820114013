import { message, notification } from 'antd';
import instance from '../../api';
import { ErrorNotification } from '../../../Utils/data';

export const vehicles = {
  async read(
    id: string | number,
    companyId: string | number,
    vinId: string | number,
    truckId: string | number,
    vehicleId: string | number,
    uid: string,
    companyUID: string,
    skip: string | number = 1,
  ) {
    if (companyId === undefined) companyId = '';
    if (truckId === undefined) truckId = '';
    if (vinId === undefined) vinId = '';
    if (vehicleId === undefined) vehicleId = '';
    try {
      const { data }: any = await instance(
        `vehicles/searching-list?page=${skip}&perPage=10&truckSearch=${truckId}&vinSearch=${vinId}&vehicleId=${vehicleId}&companyId=${companyId}&uid=${uid}&companyUid=${companyUID}`,
      );
      return {
        data: data.data,
        count: data.count,
      };
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        notification.error({
          message: 'Error 400',
          description: error.response.data.error.message,
          style: { backgroundColor: '#FFF2F0', border: '1px solid #ffccc7' },
          duration: 3,
        });
        const audio = new Audio('/bmw_warning.mp3');
        audio.play();
        console.error(error.response.data.error.message);
        throw new Error('Something went wrong');
      }
    }
  },
  async companyData(companyId: string | number | undefined) {
    if (companyId) {
      const { data }: { data: any } = await instance(`vehicles/find/${companyId}`);
      return data;
    }
  },

  async companyPatchData(companyData: any, id: any) {
    try {
      const key = 'updatable';
      message.loading({ content: 'Loading...', key });
      const { data }: { data: any } = await instance(`vehicles/edit/${id}`, {
        method: 'PATCH',
        data: companyData,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },

  async searchVehicleCompany(
    companyId: string | number | undefined,
    truck_number: string | number,
    vinId: string | number,
  ) {
    if (companyId === undefined) {
      companyId = '';
    }
    if (truck_number === undefined) {
      truck_number = '';
    }

    const { data }: any = await instance(
      `vehicles/searching-list?page=1&perPage=1000&truckSearch=${truck_number}&companyId=${companyId}&vinSearch=${vinId}`,
    );
    return data?.data;
  },

  async searchVehicleData(
    truck_number: string | number,
    companyId: string | number | undefined,
    vinId: string | number,
  ) {
    // const where = {
    //     and: [
    //         {valId},
    //         {or: [{truck_number: truck_number}]}
    //     ]
    // }
    // const {data}: { data: any } = await instance(`vehicles?filter=${JSON.stringify({where: where})}`);
    // return data;
    if (companyId === undefined) companyId = '';
    if (truck_number === undefined) truck_number = '';
    if (vinId === undefined) vinId = '';

    const { data }: any = await instance(
      `vehicles/searching-list?page=1&perPage=1000&truckSearch=${truck_number}&companyId=${companyId}&vinSearch=${vinId}`,
    );
    return data?.data;
  },
  async searchVehicleVinData(
    vin: string | number,
    companyId: string | number | undefined,
    truckId: string | number,
  ) {
    // const where = {
    //     and: [
    //         {valId},
    //         {or: [{vin: vin}]}
    //     ]
    // }
    // const {data}: { data: any } = await instance(`vehicles?filter=${JSON.stringify({where: where})}`);
    // return data;

    if (companyId === undefined) companyId = '';
    if (truckId === undefined) truckId = '';
    if (vin === undefined) vin = '';

    const { data }: any = await instance(
      `vehicles/searching-list?page=1&perPage=1000&vinSearch=${vin}&companyId=${companyId}&truckSearch=${truckId}`,
    );
    return data?.data;
  },

  async searchVehicleId(vehicleId: string | number, valId: string | number) {
    if (valId === undefined) {
      valId = '';
    }

    const { data }: any = await instance(
      `vehicles/searching-list?page=1&perPage=1000&vehicleId=${vehicleId}&companyId=${valId}`,
    );
    return data?.data;
  },

  async getMakes() {
    let url = `makes/list?perPage=1000&isActive=true&relationModels=true`;
    const { data } = await instance(url);
    return data;
  },
  async getModels(_: any, { makeId }: { makeId: any }) {
    let url = `models/list?makeId=${makeId}&perPage=1000`;
    const { data } = await instance(url);
    return data?.data?.map((model: any) => ({
      value: model?.id,
      label: model?.name,
    }));
  },
};

export const getModels: any = async (_: any, { makeId }: { makeId: any }) => {
  let url = `models/list?makeId=27&perPage=1000`;
  const { data } = await instance(url);
  return data?.data?.map((model: any) => ({
    value: model?.id,
    label: model?.name,
  }));
};
