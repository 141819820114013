import React, { useState } from 'react';
import { useUsersData } from '../../../../../../../Hooks/TT_ELD/Users';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import SearchInput from '../../../../../../../Utils/SearchInput';
import UsersTable from '../../../Users/UsersTable';
import useRouteQuery from '../../../../../../../Utils/useRouterQuery';

type Data = {
  data?: {
    data: Array<any>;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: any;
};
type params = {
  readonly id: any;
};
const TabDrivers = () => {
  const { id } = useParams<params>();
  const [skip, setSkip] = useState(1);
  const [userName, setUserName] = useState<undefined | number | string>('');
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [status, setStatus] = useState<boolean | null | string>(null);
  const [driverId, setDriverId] = useRouteQuery('', 'driverId', (v) => String(v));
  const [driverEmail, setDriverEmail] = useRouteQuery('', 'driverEmail', (v) => String(v));
  const [uid, setUid] = useRouteQuery('', 'uid', (v) => String(v));
  const [companyUID, setCompanyUID] = useRouteQuery('', 'companyUID', (v) => String(v));

  const companyId: number = parseInt(id);
  const { data, refetch, isLoading, isFetching }: Data = useUsersData(
    companyId,
    'driver',
    status,
    driverId,
    driverEmail,
    userName,
    '',
    uid,
    companyUID,
    skip,
  );
  const onChange = (query: any) => {
    setSkip(query.current);
  };
  const handleChange = (event: string, inputName: string) => {
    const updateFunctions: Record<string, (value: string) => void> = {
      driverId: setDriverId,
      driverEmail: setDriverEmail,
      uid: setUid,
      companyUID: setCompanyUID,
    };

    if (updateFunctions[inputName]) {
      updateFunctions[inputName](event.trim());
    }
  };

  return (
    <div>
      <SearchInput
        value={driverId}
        placeholder="Driver name"
        onDebounceChange={(event: string) => handleChange(event, 'driverId')}
      />
      <SearchInput
        value={driverEmail}
        placeholder="Driver email"
        onDebounceChange={(event: string) => handleChange(event, 'driverEmail')}
      />
      <SearchInput
        value={uid}
        placeholder="Search by UID"
        onDebounceChange={(event: string) => handleChange(event, 'uid')}
      />
      <SearchInput
        value={uid}
        placeholder="Search by Company UID"
        onDebounceChange={(event: string) => handleChange(event, 'companyUID')}
      />
      <Button style={{ float: 'right', marginLeft: '15px' }} size={'large'} onClick={refetch}>
        Refresh
      </Button>
      <UsersTable
        data={data?.data}
        total={data?.count}
        isLoading={isLoading}
        isFetching={isFetching}
        setSortedInfo={setSortedInfo}
        sortedInfo={sortedInfo}
        setSkip={setSkip}
        refetch={refetch}
        onChange={onChange}
        pagination={{ total: data?.count, showSizeChanger: false }}
      />
    </div>
  );
};

export default TabDrivers;
