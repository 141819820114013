import { AutoComplete, Input } from "antd";
import React, {useCallback,  useState} from "react";

const SearchOptions = ({
  SearchResult,
  onSelect,
  placeholder,
  defaultValue,
    value,
   refInput,
   SelectWidth,
   enterButtonSize
}: {
  SearchResult: any;
  onSelect: any;
  placeholder: string;
  defaultValue?: string | undefined;
  value?:string
  refInput?:any
  SelectWidth?:any
  enterButtonSize?:string
}) => {
  const [options, setOptions] = useState<Array<any>>([]);


  const debounce = (func: any) => {
    let timer: any;
    return function (...args: any) {
      // const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func(args[0]);
      }, 2000);
    };
  };

  const handleSearch = async (value: string) => {
    if (value) {
      let data = await SearchResult(value);
      if (data.length === 0) {
        data = [{ value: 'Not found', label: 'Not found ', key: 'no-found' }];
      }
      setOptions(data);
    } else {
      setOptions([]);
    }
  };

  const optimizedFn = useCallback(debounce(handleSearch), []);

  return (
    <AutoComplete
      popupMatchSelectWidth={SelectWidth || 252}
      style={{ width: 300, marginRight:'15px' }}
      options={options}
      onSelect={onSelect}
      onSearch={optimizedFn}
      defaultValue={defaultValue}
      onChange={onSelect}
    >
      <Input.Search className={enterButtonSize ? 'custom-search-input' : ''} ref={refInput} placeholder={placeholder} allowClear enterButton />
    </AutoComplete>
  );
};

export default SearchOptions;
