import type { Notice } from '../../interface/layout/notice.interface';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined, UserAddOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import { Badge, List, notification, Popover, Spin, Tabs, Tag } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ReactComponent as NoticeSvg } from '../../assets/header/notice.svg';
import { EventStatus } from '../../interface/layout/notice.interface';
import { useLocale } from '../../locales';
import { useApproval, useApprovalCount } from '../../../Hooks/TT_ELD/Approval';
import { useFMCSA, useFmcsaCount } from '../../../Hooks/TT_ELD/FMCSA';
import RefreshContext from '../../utils/context';

type Data = {
  data?: {
    data: any;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: any;
};

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin rev={undefined} />;

const { TabPane } = Tabs;

const HeaderNoticeComponent: FC = () => {
  const { refetchBtn } = useContext(RefreshContext);

  const noticeFmcsaCount: Data = useFmcsaCount();
  const noticeApproval: Data = useApprovalCount();

  const fmcsaCount = localStorage.getItem('fmcsa_count');
  const userCount = localStorage.getItem('user_count');
  const notificationRef = useRef<ReturnType<typeof notification.info>>();

  const showNotification = () => {
    notificationRef.current = notification.info({
      message: 'Approval !',
      description: 'New User registered',
      duration: 4,
    });

    const audio = new Audio('/notif.mp3');
    audio.addEventListener('canplaythrough', () => {
      // audio.play();
    });
  };
  useEffect(() => {
    if (userCount !== undefined) {
      if (
        noticeApproval?.data?.count &&
        noticeApproval?.data?.count !== 0 &&
        noticeApproval?.data?.count > Number(userCount)
      ) {
        showNotification();
        localStorage.setItem('user_count', `${noticeApproval?.data?.count}`);
      }
    } else {
      localStorage.setItem('user_count', `${noticeApproval?.data?.count}`);
    }
  }, [noticeApproval?.data?.count]);

  const showNotificationFMCSA = async () => {
    notificationRef.current = notification.info({
      message: 'FMCSA!',
      description: 'New FMCSA Report report created',
      placement: 'topRight',
    });

    const audio = new Audio('/notif.mp3');
    audio.addEventListener('canplaythrough', () => {
      // audio.play();
    });
  };
  useEffect(() => {
    if (fmcsaCount !== undefined) {
      if (
        noticeFmcsaCount?.data?.count &&
        noticeFmcsaCount?.data?.count !== 0 &&
        noticeFmcsaCount?.data?.count > Number(fmcsaCount)
      ) {
        showNotificationFMCSA();
        localStorage.setItem('fmcsa_count', `${noticeFmcsaCount?.data?.count}`);
      }
    } else {
      localStorage.setItem('fmcsa_count', `${noticeFmcsaCount?.data?.count}`);
    }
  }, [noticeFmcsaCount?.data?.count]);

  const { data, refetch, isLoading, isFetching }: Data = useApproval('', '', '', '');
  const fmcsa: Data = useFMCSA('', '');
  let count = Number(noticeFmcsaCount?.data?.count) + Number(noticeApproval?.data?.count);

  const [visible, setVisible] = useState(false);
  const [noticeList, setNoticeList] = useState<Notice[]>([]);
  const [loading, setLoading] = useState(false);
  const { noticeCount } = useSelector((state: any) => state.user);
  const { formatMessage } = useLocale();
  const noticeListFilter = <T extends Notice['type']>(type: T) => {
    return noticeList.filter((notice) => notice.type === type) as Notice<T>[];
  };

  // loads the notices belonging to logged in user
  // and sets loading flag in-process
  const getNotice = async () => {
    setLoading(true);
    // const {status, result} = await getNoticeList();

    setLoading(false);
    // status && setNoticeList(result);
  };

  useEffect(() => {
    getNotice();
  }, []);

  useEffect(() => {
    if (refetchBtn) {
      refetch();
      fmcsa?.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchBtn]);

  const tabs = (
    <div>
      <Spin tip="Loading..." indicator={antIcon} spinning={loading}>
        <Tabs defaultActiveKey="1">
          <TabPane tab={<span>Account ({data?.count})</span>} key="1">
            <List
              dataSource={data?.data}
              renderItem={(data: any) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<UserAddOutlined style={{ fontSize: '22px' }} rev={undefined} />}
                    title={
                      <Link to="/account/approval">
                        {data?.first_name} {data?.second_name}
                      </Link>
                    }
                    description={moment(data?.createdAt).format('YYYY-MM-DD / hh:mm A')}
                  />
                </List.Item>
              )}
            />
          </TabPane>

          <TabPane tab={<span>FMCSA ({noticeFmcsaCount?.data?.count})</span>} key="2">
            <List
              dataSource={fmcsa?.data?.data}
              renderItem={(data: any) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<AppstoreAddOutlined style={{ fontSize: '22px' }} rev={undefined} />}
                    title={<Link to="/fmcsa">{data?.company?.name} </Link>}
                    description={moment(data?.createdAt).format('YYYY-MM-DD / hh:mm A')}
                  />
                </List.Item>
              )}
            />
          </TabPane>
          <TabPane
            tab={`${formatMessage({
              id: 'app.notice.tasks',
            })}(${noticeListFilter('event').length})`}
            key="3"
          >
            <List
              dataSource={noticeListFilter('event')}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <div className="notice-title">
                        <div className="notice-title-content">{item.title}</div>
                        <Tag color={EventStatus[item.status]}>{item.extra}</Tag>
                      </div>
                    }
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  );

  return (
    <Popover
      content={tabs}
      overlayClassName="bg-2"
      placement="bottomRight"
      trigger={['click']}
      open={visible}
      onOpenChange={(v: any) => setVisible(v)}
      overlayStyle={{
        width: 336,
      }}
    >
      {/*<Tooltip title='dd'>*/}
      <Badge style={{ cursor: 'pointer' }} size="small" count={isLoading ? '' : count}>
        <NoticeSvg style={{ color: '#1f1604', cursor: 'pointer' }} />
        {/*<ApprovalCount/>*/}
        {/*<FmcsaCount/>*/}
      </Badge>
      {/*</Tooltip>*/}

      {/*  <Tooltip*/}
      {/*      title={formatMessage({*/}
      {/*          id: 'gloabal.tips.theme.noticeTooltip',*/}
      {/*      })}*/}
      {/*  >*/}
      {/*      <Badge count={noticeCount} overflowCount={999}>*/}
      {/*<span className="notice" id="notice-center">*/}
      {/*  <NoticeSvg className="anticon"/>*/}
      {/*</span>*/}
      {/*      </Badge>*/}
      {/*  </Tooltip>*/}
    </Popover>
  );
};

export default HeaderNoticeComponent;
