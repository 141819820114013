import React, { useState } from 'react';
import { useCompaniesData } from '../../../../../Hooks/TT_ELD/Companies';
import CompaniesTable from './CompaniesTable';
import { Button } from 'antd';
import SearchInput from '../../../../../Utils/SearchInput';
import useRouteQuery from '../../../../../Utils/useRouterQuery';
import SearchOptions from '../../../../../Utils/SearchOptions';
import { SearchForOwnerDispatchers } from '../../../../../Utils/SearchResults';

type Data = {
  data?: {
    data: Array<any>;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: any;
};

const Companies = () => {
  const [companyId, setCompanyId] = useRouteQuery('', 'companyId', (v) => String(v));
  const [usdotId, setUsdotId] = useRouteQuery('', 'usdotId', (v) => String(v));
  const [uid, setUid] = useRouteQuery('', 'uid', (v) => String(v));
  const [ownerId, setOwnerId] = useState('');
  const [isDispute, setiSDispute] = useState<string>('true');
  const handleChange = (event: string, inputName: string) => {
    const updateFunctions: Record<string, (value: string) => void> = {
      companyId: setCompanyId,
      usdotId: setUsdotId,
      uid: setUid,
    };

    if (updateFunctions[inputName]) {
      updateFunctions[inputName](event.trim());
      setSkip(1);
    }
  };
  const [skip, setSkip] = useState<number>(1);
  const { data, refetch, isLoading, isFetching }: Data = useCompaniesData(
    companyId,
    usdotId,
    undefined,
    skip,
    '',
    ownerId,
    isDispute,
    uid,
  );
  const onChange = (query: any) => {
    setSkip(1 * parseInt(query.current));
  };
  return (
    <div className="mainPage">
      <SearchInput
        value={companyId}
        placeholder="Search by company"
        onDebounceChange={(event: string) => handleChange(event, 'companyId')}
      />
      <SearchInput
        value={usdotId}
        placeholder="Search by USDOT"
        onDebounceChange={(event: string) => handleChange(event, 'usdotId')}
      />
      <SearchOptions
        SearchResult={SearchForOwnerDispatchers}
        onSelect={(value: any, { valId }: { valId: any }) => {
          setOwnerId(valId);
          if (valId) setSkip(1);
        }}
        placeholder="Search by owner"
        value={''}
      />
      <SearchInput
        value={uid}
        placeholder="Search by UID"
        onDebounceChange={(event: string) => handleChange(event, 'uid')}
      />

      <Button style={{ float: 'right', marginLeft: '15px' }} size={'large'} onClick={refetch}>
        Refresh
      </Button>
      <CompaniesTable
        companiesData={data?.data}
        total={data?.count}
        onChange={onChange}
        isLoading={isLoading}
        isFetching={isFetching}
        skip={skip}
        refetch={refetch}
      />
    </div>
  );
};

export default Companies;
