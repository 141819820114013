import React, { useState } from 'react';
import ELDTable from './ELDTable';
import { Button } from 'antd';
import useRouteQuery from '../../../../../Utils/useRouterQuery';
import { useELDModels } from '../../../../../Hooks/TT_ELD/ELDModels';
import SearchOptions from '../../../../../Utils/SearchOptions';
import { SearchResultForCompany } from '../../../../../Utils/SearchResults';
import AddELDList from './AddLogBook';

type Data = {
  data?: {
    data: Array<any>;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: any;
};

const Makes = () => {
  const showModal = () => {
    setOpen(true);
  };
  const [open, setOpen] = useState(false);
  const [companyId, setCompanyId] = useRouteQuery('', 'companyId', (v) => String(v));
  const [skip, setSkip] = useState<string | number>(1);
  const onChange = (query: any) => {
    setSkip(1 * parseInt(query.current));
  };
  const { data, isLoading, isFetching, refetch }: Data = useELDModels(companyId, skip);

  return (
    <div className="mainPage">
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {open && <AddELDList open={open} setOpen={setOpen} refetch={refetch} />}
        <SearchOptions
          SearchResult={SearchResultForCompany}
          onSelect={(value: any, { valId }: { valId: any }) => {
            if (valId) {
              setCompanyId(valId);
            }
          }}
          placeholder="Search by company"
          value={''}
        />

        <Button type="primary" style={{ marginLeft: 'auto' }} size={'large'} onClick={showModal}>
          ADD ELD Model
        </Button>
        <Button size={'large'} style={{ marginLeft: '15px' }} onClick={refetch}>
          Refresh
        </Button>
      </span>
      <ELDTable
        make={data?.data}
        total={data?.count}
        onChange={onChange}
        isLoading={isLoading}
        isFetching={isFetching}
        refetch={refetch}
        skip={skip}
      />
    </div>
  );
};

export default Makes;
