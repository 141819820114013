import React, { useState } from 'react';
import { Button, Card, Col, DatePicker, Form as FormAnt, Input } from "antd";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { sendEvent } from "../../../../../../../Utils/analytics";
import { users } from "../../../../../../../API/TT_ELD/Users/user";

const { RangePicker } = DatePicker;
interface Params {
    id: string | undefined;
    [key: string]: string | undefined;
}

const FMCSADriver = ({ uid }: { uid: string }) => {
    const { id } = useParams<Params>();
    const [form] = FormAnt.useForm();
    const [range, setRange] = useState<any[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const [skip, setSkip] = useState(1);

    const onSubmit = async (values: any) => {
        try {
            await users.fmcsaDriver(id, skip, range, uid, values);
            sendEvent(`Submit button Subscription page`, `Submit button Subscription page`, `Submit Subscription `, 1);
        } catch (error) {
            console.error(error);
        }
    };

    const isButtonDisabled = range.length === 0 || !inputValue.trim();

    return (
        <div className='mainPage'>
            <Card>
                <RangePicker
                    onChange={(value: any) => {
                        setRange(value ? value.map((item: any) => dayjs(item).format('DD-MM-YYYY')) : []);
                    }}
                    clearIcon
                />
                <FormAnt
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    onFinish={onSubmit}
                >
                    <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                        <span style={{ display: 'flex', marginTop: '40px' }}>
                            <Col>
                                <FormAnt.Item name="comment">
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="Enter your feedback"
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                    />
                                </FormAnt.Item>
                            </Col>
                            <FormAnt.Item style={{ marginLeft: '20px' }}>
                                <Button type="primary" htmlType="submit" disabled={isButtonDisabled}>
                                    Submit
                                </Button>
                            </FormAnt.Item>
                        </span>
                    </span>
                </FormAnt>
            </Card>
        </div>
    );
};

export default FMCSADriver;
