import React from 'react';
import { Spin, Table, Tag } from 'antd';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { getStatus } from '../../../../../../Utils/data';
import { calcOrder } from '../../../../../components/core/calcOrder';

type numStr = string | number;

interface contactSource {
  no: numStr;
  user: { first_name: string; second_name: string; id: String };
  updatedAt: numStr;
  is_active: boolean;
  model: numStr;
  versionCode: numStr;
  device_type: numStr;
  app_version: numStr;
  companyId: numStr;
  status: string | null;
  info: { userId: number | string };
  key: React.Key;
}

const DevicesEldTable = ({
  data = [],
  onChange,
  total = 0,
  isLoading,
  pageId,
  skip,
}: {
  data: Array<any> | undefined;
  onChange(current: any): void;
  total: number | undefined;
  isLoading: boolean | undefined;
  setSortedInfo(sortedInfo: any): void;
  sortedInfo: any;
  setSort(sort: any): void;
  sort: boolean | undefined | string;
  setSkip(skip: any): void;
  pageId: any;
  skip: number;
}) => {
  const columns: object[] = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: ({
        first_name,
        second_name,
        id,
      }: {
        first_name: string;
        second_name: string;
        id: string;
      }) => <Link to={`/user/users/${id}`}>{first_name + ' ' + second_name}</Link>,
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Is Active',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (tag: boolean) => (
        <Tag color={tag ? 'geekblue' : 'red'}>{tag ? 'True' : 'False'}</Tag>
      ),
      // sorter: (a: any, b: any) => a.is_active.length - b.is_active.length,
      // sortOrder: sortedInfo.columnKey === "is_active" && sortedInfo.order,
      // sortDirections: ['is_active ASC', 'is_active DESC'],
      // sorter: true,
      // onHeaderCell: () => ({
      //     onClick: () => setSort(sort === 'false' ? true : 'false'),
      //     // sortDirections: [false, true, 'null'],
      //     sorter: (a: any, b: any) => a.isActive.length - b.isActive.length,
      //     sortOrder: sortedInfo.columnKey === "isActive" && sortedInfo.order,
      //     sortDirections: ['false', 'true', 'null'],
      // }),
    },
    {
      title: 'Device type',
      dataIndex: 'device_type',
      key: 'device_type',
    },
    {
      title: 'App version',
      dataIndex: 'app_version',
      key: 'app_version',
    },
    {
      title: 'Version code',
      dataIndex: 'versionCode',
      key: 'versionCode',
    },
    {
      title: 'Company ID',
      dataIndex: 'companyId',
      key: 'companyId',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      editable: true,
      width: 140,
      render: (tag: any) => {
        const TagColors: any = {
          login: 'purple',
          sleep: '#ffbb00',
          driving: '#27ae60',
          poweroff: '#858e9e',
          off: '#858e9e',
          on: '#1470c6',
          intermediate: '#ff7a45',
          personal: '#d48806',
          yard: '#1d39c4',
          logout: '#f2438d',
          poweron: '#52c41a',
          certify: '#c41d7f',
          adverse_driving: '#2d2fc4',
          short_haul: '#c41c1b',
          malfunction_1_7: '#d9d766',
          malfunction_2_7: '#d98711',
          malfunction_3_7: '#5dd9ac',
          malfunction_4_7: '#a69fd9',
          malfunction_5_7: '#d97263',
          malfunction_6_7: '#68d976',
        };
        const tagColor = TagColors[tag] || 'defaultColor';

        return (
          <>
            <Tag
              style={{ background: tagColor, color: 'white' }}
              color="white"
              className="tag-hover-effect"
            >
              {tag?.toUpperCase()}
            </Tag>
          </>
        );
      },
    },
    // {
    //     title: 'Actions',
    //     dataIndex: 'info',
    //     key: 'info',
    //     fixed: 'right',
    //     width: 150,
    //     render: (val: { userId: number | string, role: string }) =>
    //         <Space>
    //             <Link to={`/user/users/${val.userId}`}><Button>View</Button></Link>
    //         </Space>
    // },
  ];

  return (
    <div>
      <Spin size="large" spinning={isLoading}>
        <Table
          style={{ margin: '15px 0 0 0' }}
          onChange={onChange}
          dataSource={data?.map((u: any, i: number): contactSource => {
            let update = u.updatedAt;
            const obj: contactSource = {
              no: calcOrder(skip, 10, i),
              user: {
                first_name: u?.user?.first_name,
                second_name: u?.user?.second_name,
                id: u.userId,
              },
              updatedAt: update ? moment(update).format('YYYY-MM-DD, h:mm:ss a') : '',
              model: u.model,
              is_active: u.is_active,
              device_type: u.device_type,
              versionCode: u.versionCode,
              app_version: u.app_version,
              companyId: u?.user?.companyId,
              status: getStatus(u?.lastLog?.event_code, u?.lastLog?.event_type),
              info: { userId: u.userId },
              key: u.id,
            };
            return obj;
          })}
          columns={columns}
          pagination={{
            total: total,
            current: pageId ? parseInt(pageId) : 1,
            showSizeChanger: false,
          }}
        />
      </Spin>
    </div>
  );
};

export default DevicesEldTable;
