import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makesList } from '../../../../../API/TT_ELD/Users/make_moddel';
import { Button, Form as FormAnt, Input, Row, Spin, Col, Space } from 'antd';
import { Watermark } from 'antd';
import Notfound from '../../../../../Utils/Notfound';
import { useEldModelsOne } from '../../../../../Hooks/TT_ELD/ELDModels';

type MyObjectType = {
  [key: string | number]: any;
};

interface Params {
  id: string | undefined;
  [key: string]: string | undefined;
}

const ELDEdit = () => {
  const { id } = useParams<Params>();
  const { data, refetch, status }: MyObjectType = useEldModelsOne(id);
  const [loadings, setLoadings] = useState<boolean[]>([]);
  let navigate = useNavigate();
  const onSubmit = async (value: any) => {
    await makesList.makePatch(id, value);
    navigate(-1);
    refetch();
  };

  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  return (
    <div className="mainPage">
      <Watermark style={{ height: '100%' }}>
        {status === 'loading' ? (
          <Spin size="large" spinning={!data} />
        ) : data ? (
          <FormAnt
            name="basic"
            layout="vertical"
            wrapperCol={{ span: 16 }}
            initialValues={{ ...data.data }}
            onFinish={onSubmit}
            autoComplete="off"
          >
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <FormAnt.Item
                    wrapperCol={{ span: 6 }}
                    label="name"
                    name="name"
                    rules={[{ required: false, message: 'Please input your name!' }]}
                  >
                    <Input />
                  </FormAnt.Item>
                </Col>
                <Col span={6}>
                  <FormAnt.Item
                    wrapperCol={{ span: 6 }}
                    label="address"
                    name="address"
                    rules={[{ required: false, message: 'Please input your name!' }]}
                  >
                    <Input />
                  </FormAnt.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <FormAnt.Item
                    wrapperCol={{ span: 6 }}
                    label="identifier"
                    name="identifier"
                    rules={[{ required: false, message: 'Please input your name!' }]}
                  >
                    <Input />
                  </FormAnt.Item>
                </Col>
                <Col span={6}>
                  <FormAnt.Item
                    wrapperCol={{ span: 6 }}
                    label="registrationId"
                    name="registrationId"
                    rules={[{ required: false, message: 'Please input your name!' }]}
                  >
                    <Input />
                  </FormAnt.Item>
                </Col>
              </Row>
            </Space>
            <FormAnt.Item>
              <Button
                onClick={() => enterLoading(1)}
                loading={loadings[1]}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </FormAnt.Item>
          </FormAnt>
        ) : (
          <Notfound />
        )}
      </Watermark>
    </div>
  );
};

export default ELDEdit;
