import type { FC } from 'react';
import {
  DesktopOutlined,
  FolderOpenOutlined,
  PieChartOutlined,
  QrcodeOutlined,
  ReconciliationOutlined,
  UserOutlined,
  WarningOutlined,
  UserAddOutlined,
  ExceptionOutlined,
  ExclamationCircleOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import React from 'react';

interface CustomIconProps {
  type: string;
}

export const CustomIcon: FC<CustomIconProps> = (props) => {
  const { type } = props;
  let com;

  if (type === 'account') {
    com = <UserAddOutlined rev={undefined} />;
  } else if (type === 'fmcsa') {
    com = <PieChartOutlined rev={undefined} />;
  } else if (type === 'contact') {
    com = <DesktopOutlined rev={undefined} />;
  } else if (type === 'camera') {
    com = <VideoCameraOutlined rev={undefined} />;
  } else if (type === 'unpaid-companies') {
    com = <ExceptionOutlined rev={undefined} />;
  } else if (type === 'disputed-companies') {
    com = <ExclamationCircleOutlined rev={undefined} />;
  } else if (type === 'user-companies') {
    com = <ReconciliationOutlined rev={undefined} />;
  } else if (type === 'user') {
    com = <UserOutlined rev={undefined} />;
  } else if (type === 'errors') {
    com = <WarningOutlined rev={undefined} />;
  } else if (type === 'team') {
    com = <FolderOpenOutlined rev={undefined} />;
  } else if (type === 'tms') {
    com = <QrcodeOutlined rev={undefined} />;
  }

  return <span className="anticon">{com}</span>;
};
