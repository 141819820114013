import { Button, Col, Form as FormAnt, Input, Row, Select, Space } from 'antd';
import { system } from '../../../../../API/TT_ELD/Team/system';
import { useSystem } from '../../../../../Hooks/TT_ELD/Team/System';
import GetSystem from './GetSystem';
import { Spin } from 'antd/es';
import { sendEvent } from '../../../../../Utils/analytics';

const sortList = [
  {
    label: 'True',
    value: true,
  },
  {
    label: 'False',
    value: false,
  },
];
type MyObjectType = {
  [key: string | number]: any;
};

const System = () => {
  const [form] = FormAnt.useForm();
  const { data, refetch, isLoading, isFetching }: MyObjectType = useSystem();

  const onSubmit = async (values: any) => {
    const body: MyObjectType = {};
    let key: string | number;
    let key2: string | number;
    for (key in values) {
      if (typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] != null) {
        for (key2 in values[key]) {
          if (
            typeof values[key][key2] === 'object' &&
            !Array.isArray(values[key][key2]) &&
            values[key][key2] != null
          ) {
            if (JSON.stringify(values[key][key2]) != JSON.stringify(data[key][key2])) {
              body[key] = values[key];
              break;
            }
          } else if (data[key][key2] != values[key][key2]) {
            body[key] = values[key];
            break;
          }
        }
      } else if (data[key] != values[key]) {
        body[key] = values[key];
      }
    }
    await system.addSystem(body);
    refetch();
    form.resetFields();
    sendEvent(`Add button System page`, `Add button System page `, `Add System `, 1);
  };

  return (
    <div className="mainPage" style={{ paddingRight: '30px' }}>
      <Spin size="large" spinning={isLoading || isFetching}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <FormAnt
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onSubmit}
            initialValues={{ ...data }}
          >
            <Row gutter={[16, 10]}>
              <Col span={4}>
                <FormAnt.Item
                  label="type"
                  name="type"
                  rules={[{ required: true, message: 'Please enter your type!' }]}
                >
                  <Input placeholder="Enter your type" allowClear />
                </FormAnt.Item>
              </Col>

              <Col span={3}>
                <FormAnt.Item
                  label="mode"
                  name="mode"
                  rules={[{ required: true, message: 'Please enter your mode!' }]}
                >
                  <Select
                    allowClear
                    placeholder="Select mode"
                    style={{ width: '150px' }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={sortList?.map((option: any) => ({
                      value: option.value,
                      label: option.label,
                    }))}
                  />
                </FormAnt.Item>
              </Col>
              <div style={{ marginTop: '30px' }}>
                <FormAnt.Item>
                  <Button type="primary" htmlType="submit">
                    Add
                  </Button>
                </FormAnt.Item>
              </div>

              <Button style={{ marginLeft: 'auto' }} size={'large'} onClick={refetch}>
                Refresh
              </Button>
            </Row>
          </FormAnt>
        </Space>
        <GetSystem />
      </Spin>
    </div>
  );
};

export default System;
