import { Form as FormAnt, Input, Modal } from 'antd';
import { makesList } from '../../../../../API/TT_ELD/Users/make_moddel';

const AddELDList = ({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen(open: boolean): void;
  refetch(): void;
}) => {
  const handleCancel = () => {
    setOpen(!open);
  };

  const [form] = FormAnt.useForm();
  return (
    <>
      <Modal
        open={open}
        title="Create a new ELD"
        okText="Create"
        cancelText="Cancel"
        onCancel={handleCancel}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              form.resetFields();
              await makesList.addMakesList(values);
              setOpen(!open);
              refetch();
            })
            .catch((info) => {
              refetch();
            });
        }}
      >
        <FormAnt
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'public' }}
        >
          <FormAnt.Item
            name="name"
            label="name"
            rules={[{ required: true, message: 'Please enter your name!' }]}
          >
            <Input />
          </FormAnt.Item>
          <FormAnt.Item
            wrapperCol={{ span: 6 }}
            label="address"
            name="address"
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <Input />
          </FormAnt.Item>
          <FormAnt.Item
            wrapperCol={{ span: 6 }}
            label="identifier"
            name="identifier"
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <Input />
          </FormAnt.Item>
          <FormAnt.Item
            wrapperCol={{ span: 6 }}
            label="registrationId"
            name="registrationId"
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <Input />
          </FormAnt.Item>
        </FormAnt>
      </Modal>
    </>
  );
};

export default AddELDList;
