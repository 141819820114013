import React, { useState } from 'react';
import { Button, Modal, Space, Spin, Table, Tag, Typography } from 'antd';
import { getDuration, getStatus } from '../../../../../../Utils/data';
import { logs } from '../../../../../../API/TT_ELD/logs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import BulkEditModal from './BulkEditModal';
import { LockFilled } from '@ant-design/icons';
import moment from 'moment';
const { Paragraph } = Typography;

type numStr = number | string;
type strUnd = string | undefined | null;

interface logDataSource {
  no: numStr;
  id: numStr;
  status: string | null;
  start_date: strUnd;
  endDate: strUnd;
  duration: undefined | string;
  address: { address: string; lat: number | string; lng: number | string };
  odometer: numStr | undefined;
  engineHours: numStr | undefined;
  note: strUnd;
  document: numStr;
  createdAt: strUnd;
  updatedAt: strUnd;
  // trailer: numStr;
  actions: {
    id: numStr;
    queryClient: any;
    userId: string | undefined;
    companyId: number;
    record_status: number | undefined | null;
    inspection: boolean;
  };
  key: React.Key;
}

const columns: object[] = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    width: 50,
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 120,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    editable: true,
    width: 170,
    render: (tag: any) => {
      const TagColors: any = {
        login: 'purple',
        sleep: '#ffbb00',
        driving: '#27ae60',
        poweroff: '#858e9e',
        off: '#858e9e',
        on: '#1470c6',
        intermediate: '#ff7a45',
        personal: '#d48806',
        yard: '#1d39c4',
        logout: '#f2438d',
        poweron: '#52c41a',
        certify: '#c41d7f',
        adverse_driving: '#2d2fc4',
        short_haul: '#c41c1b',
        malfunction_1_7: '#d9d766',
        malfunction_2_7: '#d98711',
        malfunction_3_7: '#5dd9ac',
        malfunction_4_7: '#a69fd9',
        malfunction_5_7: '#d97263',
        malfunction_6_7: '#68d976',
        eld_connect: '#009b14',
        eld_disconnect: '#c41c1b',
        internet_disconnect: '#c41d7f',
        internet_connect: '#006418',
        app_worked: '#5dd9ac',
        app_paused: '#bec0c5',
        battery: '#F99E2C',
      };
      const tagColor = TagColors[tag] || 'defaultColor';

      return (
        <>
          <Tag
            style={{ background: tagColor, color: 'white' }}
            color="white"
            className="tag-hover-effect"
          >
            {tag?.toUpperCase()}
          </Tag>
        </>
      );
    },
  },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    key: 'start_date',
    editable: true,
    width: 210,
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    editable: true,
    width: 210,
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
    width: 100,
  },
  {
    title: 'Location',
    dataIndex: 'address',
    key: 'address',
    width: 230,
    render: ({
      address,
      lat,
      lng,
    }: {
      address: string;
      lat: number | string | undefined | any;
      lng: number | string | undefined;
    }) => {
      return (
        <>
          {address ? (
            <Paragraph style={{ marginBottom: 0 }} copyable={{ text: `${lat}, ${lng}` }}>
              {address}
            </Paragraph>
          ) : (
            ''
          )}
        </>
      );
    },
  },
  {
    title: 'Odometer',
    dataIndex: 'odometer',
    key: 'odometer',
    width: 100,
  },
  {
    title: 'Engine Hours',
    dataIndex: 'engineHours',
    key: 'engineHours',
    width: 120,
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: 100,
  },
  {
    title: 'Document',
    dataIndex: 'document',
    key: 'document',
    width: 110,
  },

  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 200,
  },
  {
    title: 'Updated at',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: 200,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    width: 175,
    fixed: 'right',
    render: ({
      id,
      queryClient,
      userId,
      companyId,
      record_status,
      inspection,
    }: {
      id: number | string;
      queryClient: any;
      userId: numStr;
      companyId: number;
      record_status: number | undefined | null;
      inspection: boolean;
    }) => {
      return (
        <Space>
          <Link to={record_status !== 0 ? `log/${id}/${companyId}` : `logzeros/${id}/${companyId}`}>
            <Button>Edit</Button>
          </Link>
          <Button
            type="primary"
            danger
            onClick={() => {
              Modal.confirm({
                title: 'Confirm',
                icon: <ExclamationCircleOutlined rev={undefined} />,
                content: 'Do you want to delete this log?',
                okText: 'Ok',
                cancelText: 'cancel',
                onOk: async () => {
                  await logs.updateLogs(
                    { id, record_status: 2 },
                    companyId,
                    record_status !== 0 ? 'log' : 'logzeros',
                  );
                  queryClient.refetchQueries(`logs/${userId}`, { active: true });
                },
              });
            }}
          >
            Delete
          </Button>
          {inspection && (
            <LockFilled style={{ color: 'green', fontSize: '16px' }} rev={undefined} />
          )}
        </Space>
      );
    },
  },
];
function LogsTable({
  logs,
  userId,
  data2,
  recordStatus,
  isLoading,
  isFetching,
  refetch,
  setIsModalVisible,
  open,
  setOpen,
  toDayDate,
}: {
  logs: any;
  userId: string | undefined;
  data2: any;
  recordStatus: number;
  isLoading: boolean | undefined;
  isFetching: any;
  refetch: () => void;
  setIsModalVisible(isModalVisible: any): void;
  open: any;
  setOpen(open: any): void;
  toDayDate: string | any;
}) {
  const queryClient: any = useQueryClient();
  const [getKey, setGetKey] = useState<React.Key[]>([]);
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: logDataSource[]) => {
      if (selectedRows.length > 0) {
        setIsModalVisible(true);
      } else {
        setIsModalVisible(false);
      }
      setGetKey(selectedRowKeys);
    },
  };

  return (
    <div>
      <Spin size="large" spinning={isLoading || isFetching}>
        <BulkEditModal
          getKey={getKey}
          recordStatus={recordStatus}
          data2={data2?.data}
          refetch={refetch}
          open={open}
          setOpen={setOpen}
          companyId={logs?.find((el: any) => el.companyId)?.companyId}
          todayDate={toDayDate}
        />
        <Table
          style={{ margin: '15px 0 0 0' }}
          size="small"
          dataSource={logs?.map((u: any, i: number): logDataSource => {
            let create = u.createdAt;
            let updated = u.updatedAt;

            const obj: logDataSource = {
              no: i + 1,
              id: u.id,
              status: getStatus(u?.event_code, u?.event_type),
              start_date: u.start_date ? moment(u.start_date).format('YYYY-MM-DD, h:mm:ss A') : '',
              endDate: u.end_date ? moment(u.end_date).format('YYYY-MM-DD, h:mm:ss A') : '',
              duration: getDuration(u.start_date, u.end_date),
              address: { address: u.address, lat: u?.coordinates?.lat, lng: u?.coordinates?.lng },
              odometer: u.odometr,
              engineHours: u.engine_hours,
              note: u.note,
              document: u.document,
              createdAt: create ? moment(create).format('YYYY-MM-DD, h:mm:ss A') : '',
              updatedAt: updated ? moment(updated).format('YYYY-MM-DD, h:mm:ss A') : '',
              actions: {
                id: u.id,
                queryClient,
                userId,
                companyId: u?.companyId,
                record_status: u.record_status,
                inspection: u.inspection,
              },
              key: u.id,
            };
            return obj;
          })}
          columns={columns}
          pagination={false}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          scroll={{ x: 1670 }}
          sticky
        />
      </Spin>
    </div>
  );
}

export default LogsTable;
