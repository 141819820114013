import React from 'react';
import { Spin, Table, Tag } from 'antd';

type numStr = string | number;

interface approvalSource {
  no: numStr;
  id: numStr;
  activitiesCount: number;
  orderNumber: number | string;
  origin: string;
  status: numStr;
  stopStatus: numStr;
  role: numStr;
  deliveryAddress: numStr;
  createdAt: numStr;
  isActive: boolean;
  key: React.Key;
}

const columns: object[] = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    sorter: {
      compare: (a: any, b: any) => a.no - b.no,
      multiple: 1,
    },
  },
  {
    title: 'Activities count',
    dataIndex: 'activitiesCount',
    key: 'activitiesCount',
  },
  {
    title: 'Origin',
    dataIndex: 'origin',
    key: 'origin',
  },
  {
    title: 'Order number',
    dataIndex: 'orderNumber',
    key: 'orderNumber',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },

  {
    title: 'Stop status',
    dataIndex: 'stopStatus',
    key: 'stopStatus',
  },
  {
    title: 'Is Active',
    dataIndex: 'isActive',
    key: 'isActive',
    render: (tag: boolean) => <Tag color={tag ? 'geekblue' : 'red'}>{tag ? 'True' : 'False'}</Tag>,
    filters: [
      {
        text: 'True',
        value: true,
      },
      {
        text: 'False',
        value: false,
      },
    ],
    onFilter: (value: any, record: any) => {
      return record.isActive === value;
    },
  },
  {
    title: 'Delivery address',
    dataIndex: 'deliveryAddress',
    key: 'deliveryAddress',
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
];

const NoteTable = ({
  data = [],
  onChange,
  total = 0,
  isLoading,
  isFetching,
}: {
  data: Array<any>;
  onChange(current: any): void;
  total: number | undefined;
  isLoading: any;
  isFetching: any;
}) => {
  return (
    <div>
      <Spin size="large" spinning={isLoading || isFetching}>
        <Table
          style={{ margin: '15px 0 0 0' }}
          onChange={onChange}
          dataSource={data?.map((u: any, i: number): approvalSource => {
            const obj: approvalSource = {
              no: i + 1,
              id: u.id,
              activitiesCount: u?.load?.activitiesCount,
              orderNumber: u?.load?.orderNumber,
              origin: u?.load.origin,
              role: u.role,
              status: u?.load?.status,
              stopStatus: u?.load?.stopStatus,
              deliveryAddress: u.load?.deliveryAddress,
              createdAt: u?.createdAt,
              isActive: u?.load?.isActive,
              key: u.id,
            };
            return obj;
          })}
          columns={columns}
          pagination={{ total: total, pageSizeOptions: [10, 50, 100] }}
        />
      </Spin>
    </div>
  );
};

export default NoteTable;
