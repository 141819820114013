import React, { useState } from 'react';
import VehicleTable from './VehicleData/VehicleTable';
import { useVehicleData } from '../../../../../Hooks/TT_ELD/Vehicles';
import SearchOptions from '../../../../../Utils/SearchOptions';
import {
  SearchForTruckId,
  SearchForVehicleId,
  SearchForVehicleVin,
  SearchResultForCompany,
} from '../../../../../Utils/SearchResults';
import { Button, Input } from 'antd';
import { useParams } from 'react-router-dom';
import { AutoComplete } from 'antd';
import SearchInput from '../../../../../Utils/SearchInput';
import useRouteQuery from '../../../../../Utils/useRouterQuery';

type params = {
  readonly id: any;
};
type Data = {
  data?: {
    data: Array<any>;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: any;
};

const VehiclesCompany = () => {
  const { id } = useParams<params>();

  const [skip, setSkip] = useState<number>(1);
  const [companyId, setCompanyId] = useState<string | number>(id);
  const [truckId, setTruckId] = useState<string | number>('');
  const [vehicleId, setVehicleId] = useState<string | number>('');
  const [vinId, setVinId] = useState<string | number>('');
  const [uid, setUid] = useRouteQuery('', 'uid', (v) => String(v));
  const [companyUID, setCompanyUID] = useRouteQuery('', 'companyUID', (v) => String(v));

  const { data, refetch, isLoading, isFetching }: Data = useVehicleData(
    id,
    companyId,
    vinId,
    truckId,
    vehicleId,
    uid,
    companyUID,
    skip,
  );
  const onChange = (query: any) => {
    setSkip(1 * parseInt(query.current));
  };

  const handleChange = (event: string, inputName: string) => {
    const updateFunctions: Record<string, (value: string) => void> = {
      companyUID: setCompanyUID,
      uid: setUid,
    };

    if (updateFunctions[inputName]) {
      updateFunctions[inputName](event.trim());
      setSkip(1);
    }
  };

  const [optionsTruck, setOptionsTruck] = useState<Array<any>>([]);
  const [optionsVin, setOptionsVin] = useState<Array<any>>([]);
  const [optionsVehicle, setOptionsVehicle] = useState<Array<any>>([]);
  const handleSearchTruck = async (value: string) => {
    if (value) {
      let data = await SearchForTruckId(value, companyId, vinId);
      setOptionsTruck(data);
    } else {
      setOptionsTruck([]);
    }
  };
  const handleSearchVin = async (value: string) => {
    if (value) {
      let data = await SearchForVehicleVin(value, companyId, truckId);
      setOptionsVin(data);
    } else {
      setOptionsVin([]);
    }
  };
  const handleSearchVehicle = async (value: string) => {
    if (value) {
      let data = await SearchForVehicleId(value, companyId);
      setOptionsVehicle(data);
    } else {
      setOptionsVehicle([]);
    }
  };
  const handleChangeTruck: any = (value: any, { valId }: { valId: number | string }) => {
    setTruckId(valId);
    if (valId) {
      setSkip(1);
    }
  };
  const handleChangeVin: any = (value: any, { valId }: { valId: number | string }) => {
    setVinId(valId);
    if (valId) {
      setSkip(1);
    }
  };
  const handleChangeVehicle: any = (value: any, { valId }: { valId: number | string }) => {
    setVehicleId(valId);
    if (valId) {
      setSkip(1);
    }
  };

  return (
    <div className="mainPage">
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {!id && (
          <SearchOptions
            SearchResult={SearchResultForCompany}
            onSelect={(value: any, { valId }: { valId: number | string }) => {
              setCompanyId(valId);
              if (valId) {
                setSkip(1);
              }
            }}
            placeholder="Search by Company"
            value={''}
          />
        )}
        <AutoComplete
          options={optionsTruck}
          style={{ width: 300, marginRight: '15px' }}
          onSearch={handleSearchTruck}
          onChange={handleChangeTruck}
        >
          <Input.Search allowClear placeholder="Search by truck" enterButton />
        </AutoComplete>
        <AutoComplete
          options={optionsVin}
          style={{ width: 300, marginRight: '15px' }}
          onSearch={handleSearchVin}
          onChange={handleChangeVin}
        >
          <Input.Search allowClear placeholder="Search by VIN" enterButton />
        </AutoComplete>
        <AutoComplete
          options={optionsVehicle}
          style={{ width: 300, marginRight: '15px' }}
          onSearch={handleSearchVehicle}
          onChange={handleChangeVehicle}
        >
          <Input.Search allowClear placeholder="Search by vehicle" enterButton />
        </AutoComplete>
        <SearchInput
          value={uid}
          placeholder="Search by UID"
          onDebounceChange={(event: string) => handleChange(event, 'uid')}
        />
        <SearchInput
          value={companyUID}
          placeholder="Search by Company UID"
          onDebounceChange={(event: string) => handleChange(event, 'companyUID')}
        />
        <Button style={{ marginLeft: 'auto' }} size={'large'} onClick={refetch}>
          Refresh
        </Button>
      </span>
      <VehicleTable
        vehicle={data?.data}
        total={data?.count}
        onChange={onChange}
        isLoading={isLoading}
        isFetching={isFetching}
        skip={skip}
      />
    </div>
  );
};

export default VehiclesCompany;
