import React, { useState } from 'react';
import FMCSA_Table from './FMCSA_Table/FMCSA_Table';
import { useFMCSA, useFmcsaCount } from '../../../../Hooks/TT_ELD/FMCSA';
import { Button } from 'antd';

type Data = {
  data?: {
    data: any;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: boolean;
};
const Fmcsa = () => {
  const [skip, setSkip] = useState<number>(1);
  const [status, setStatus] = useState<string>('checked');
  const { data, refetch, isLoading, isFetching }: Data = useFMCSA(status, skip);
  const count = useFmcsaCount();
  const onChange = (query: any) => {
    setSkip(1 * parseInt(query.current));
  };

  const updates = () => {
    refetch();
    count.refetch();
  };

  return (
    <div className="">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button style={{ margin: '0 0 6px auto' }} size={'large'} onClick={updates}>
          Refresh
        </Button>
      </div>
      <FMCSA_Table
        data={data?.data}
        onChange={onChange}
        total={data?.count}
        isLoading={isLoading}
        isFetching={isFetching}
        refetch={updates}
        status="checking"
        skip={skip}
      />
    </div>
  );
};

export default Fmcsa;
