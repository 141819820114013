import React, { useEffect, useState } from 'react';
import { Button, Card, Space, DescriptionsProps, Descriptions } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCompanyById } from '../../../../../../Hooks/TT_ELD/Supports';
import { Form, Field } from 'react-final-form';
import { supports } from '../../../../../../API/TT_ELD/supports';
import TextArea from 'antd/es/input/TextArea';
type Data = {
  data?: {
    data: Array<any>;
    count: number;
  };
};

type params = {
  readonly id: any;
};

const SupportNote = ({ support = [], refetch }: { support: any; refetch: any }) => {
  const [info, setInfo] = useState<any>([]);
  let navigate = useNavigate();
  const { id } = useParams<params>();
  const { data }: { data?: Data } = useGetCompanyById(id);
  const [companyId, setCompanyId] = useState();
  useEffect(() => {
    if (data) {
      setInfo(data);
    }
    setCompanyId(id);
  }, [data]);

  const onSubmit = async (values: any) => {
    values = {
      ...values,
      note: values.note.trim(),
    };
    await supports.checkedCompanies(values, id);
    navigate(-1);
  };

  const onClick = async () => {
    await supports.postNoteSupports(companyId);
    refetch();
  };
  const validator = (value: string) => {
    if (value) {
      if (value.length <= 4 || value.trim() === '') {
        return 'Note must be longer than 4 characters';
      } else {
        return undefined;
      }
    } else {
      return ' ';
    }
  };

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Name',
      children: info.name,
    },
    {
      key: '2',
      label: 'Owner',
      children: info?.email,
    },
    {
      key: '3',
      label: 'ID',
      children: info?.id,
    },
    {
      key: '4',
      label: 'Quantity',
      children: info.stripe?.subscription?.quantity,
    },
  ];
  return (
    <Card bordered={false}>
      <Space style={{ maxWidth: '600px' }} direction="vertical" size="middle">
        <>
          <Button
            disabled={support[0]?.status === 'checking' ? true : false}
            type="primary"
            onClick={onClick}
          >
            Start
          </Button>
          <Form
            onSubmit={onSubmit}
            initialValues={id}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <label>Note</label>
                  <Field
                    validate={validator}
                    name="note"
                    render={({ input, meta }: { input: any; meta: any }) => (
                      <div>
                        {' '}
                        <TextArea placeholder="note" type="text" {...input} />
                        {meta.error && (
                          <div style={{ fontSize: 12, color: '#F24C53' }}>{meta.error}</div>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="buttons" style={{ marginTop: '15px' }}>
                  <Button
                    disabled={support[0]?.status === 'checking' ? false : true}
                    type="primary"
                    htmlType="submit"
                  >
                    Stop
                  </Button>
                </div>
              </form>
            )}
          />
        </>
        <Descriptions style={{ marginTop: 20 }} column={{ xxl: 2 }} size="middle" items={items} />
      </Space>
    </Card>
  );
};

export default SupportNote;
