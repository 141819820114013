import React, { useEffect } from 'react';
import { useFmcsaCount } from '../../../../Hooks/TT_ELD/FMCSA';
import { notification } from 'antd';

type Data = {
  data?: {
    data: any;
    count: number;
  };
  status: boolean | string;
};

const FmcsaCount = () => {
  const { data, status }: Data = useFmcsaCount();
  const fmcsaCount = localStorage.getItem('fmcsa_count');

  const showNotification = () => {
    notification.info({
      message: 'New FMCSA!',
      description: 'Please check FMCSA page',
      placement: 'topRight',
    });

    // const audio = new Audio('/notification.mp3');
    // audio.play();
  };

  useEffect(() => {
    if (fmcsaCount !== undefined) {
      if (data?.count && data?.count !== 0 && data?.count > Number(fmcsaCount)) {
        showNotification();
        localStorage.setItem('fmcsa_count', `${data?.count}`);
      }
    } else {
      localStorage.setItem('fmcsa_count', `${data?.count}`);
    }
  }, [data?.count]);

  return (
    <>
      {/*{status === 'loading' ? (*/}
      {/*    <Spin indicator={antIcon} />*/}
      {/*) : data?.count !== 0 ? (*/}
      {/*    <Badge style={{ marginRight: '10px' }} size="small" count={data?.count} />*/}
      {/*) : (*/}
      {/*    <PieChartOutlined rev={undefined} />*/}
      {/*)}*/}
    </>
  );
};

export default FmcsaCount;
