import instanceTMS from '../apiClevertms';
import { message } from 'antd';

interface Field {
  status: string;
  applied: string;
  confirmedByDriver: string;
  billingStatus: string;
  orderNumber: string;
  brokerEmails: Array<string>;
  notes: string;
  brokerCompanyId: string;
  brokerEmail: string;
  reason: string;
  deliveryAddress: string;
  stopStatus: string;
  origin: string;
  completed: string;
  puDateTo: string;
  puDateFrom: string;
  delDateFrom: string;
  delDateTo: string;
  currentStop: number;
  unitId: number;
  statusCode: number;
  dispatcherId: number;
  driverId: number;
  coDriverId: number;
  rate: number;
  mile: number;
  cancelPrice: number;
  price: number;
  emptyMile: number;
  calculatedPrice: number;
  driverLoadCompleted: boolean;
  paid: boolean;
  roundTrip: boolean;
  isPaidSalary: boolean;
  isDispatched: boolean;
  isActive: boolean;
  calculatedData: object;
  id: string;
}

export const loadController = {
  // async read(field: Field, innerRelation: string, relationForInclude: string, skip: undefined | number = 0) {
  //     let whereDefault = { "limit": 100, "skip": skip, "order": "createdAt DESC", "where": {}}
  //     let fields = {}
  //     // fields = field ? { ...fields, field} : field
  //     let filter = { ...whereDefault}
  //     //     , "include": [
  //     //     {
  //     //         "relation": `${innerRelation} DESC`,
  //     //         "scope": {
  //     //             "offset": 0,
  //     //             ...whereDefault,
  //     //             "fields": {},
  //     //             "include": new Array({"additionalProp1": {}})}
  //     //     },
  //     //     `${relationForInclude}`
  //     // ]
  //
  //     const {data}: { data: object } = await instance(`admin/loads?filter=${JSON.stringify(filter)}`);
  //     const getCount = async () => {
  //         return 0;
  //     }
  //     const count = await getCount();
  //
  //     return {data, count: count};
  // },

  async read(orderNumber: string | undefined | number, date: any, skip: string | number) {
    let url =
      (date && !date) || !date[0] || !date[1]
        ? `admin/loads?page=${skip}&perPage=10&search=${orderNumber}`
        : `admin/loads?page=${skip}&perPage=10&search=${orderNumber}&fromDate=${date[0]}&toDate=${date[1]}`;

    const { data }: any = await instanceTMS(`${url}`);
    const getCount = async () => {
      return 0;
    };

    return {
      data: data.data,
      count: data?.total,
    };
  },

  async loadControllerOne(companyData: string | number | undefined) {
    const { data }: { data: any } = await instanceTMS(`admin/loads/${companyData}`);
    const {
      isDemo,
      createdAt,
      updatedAt,
      attachment,
      brokerEmails,
      code,
      companyId,
      currentLocation,
      delDate,
      driverAmount,
      isPaid,
      nextStop,
      noteColor,
      puDate,
      state,
      tagsString,
      trackingNumber,
      tripMl,
      ...rest
    } = data;
    return { ...rest };
  },

  async loadControllerPatch(companyData: Field) {
    const { id, ...rest } = companyData;
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });
    const { data }: { data: any } = await instanceTMS(`admin/loads/${id}`, {
      method: 'PATCH',
      data: rest,
    }).then((u) => {
      setTimeout(() => {
        message.success({ content: 'Loaded!', key, duration: 2 });
      }, 1000);
      return u;
    });
    return data;
  },

  async addLoadController(companyId: any) {
    message.loading({ content: 'Loading...', key: companyId });
    const { data } = await instanceTMS('/admin/loads/', {
      method: 'POST',
      data: {
        ...companyId,
      },
    }).then((u) => {
      setTimeout(() => {
        message.success({ content: 'Loaded!', key: companyId, duration: 2 });
      }, 1000);
      return u;
    });
    return data;
  },
  async deleteLoadController(id: number | string) {
    message.loading({ content: 'Loading...', key: id });
    let res;
    let error = '';
    try {
      const { data } = await instanceTMS(`/admin/loads/${id}`, {
        method: 'DELETE',
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Deleted!', key: id, duration: 2 });
        }, 1000);
        return u;
      });
      res = data;
    } catch (err) {
      error = 'Oops something went wrong!';
    }
    return { data: res, error };
  },

  async driverFinderLoad(orderNumber: number | string) {
    const { data }: { data: Array<any> } = await instanceTMS(`admin/loads?search=${orderNumber}`);
    return data;
  },
};
