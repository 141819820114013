import React, {useState} from 'react';
import {Avatar, Button, Modal, Space, Spin, Table, Tag, Tooltip} from "antd";
import {Link, useLocation} from "react-router-dom";
import moment from "moment/moment";
import LogoLight from "../../../../assets/logo/logoZippy.svg";
import EVO from "../../../../assets/logo/evodark.svg";
import darkTTeld from "../../../../assets/logo/logoDark.svg";
import lightTTeld from "../../../../assets/logo/lightTTeld.svg";
import {useSelector} from "react-redux";

interface userDataSource {
    id: number | string;
    img: {whichservice: string } ;
    name: { name: string, id: string | number };
    company: { name: string, companyId: string | number };
    logbook: { name: string, id: string | number };
    role: string,
    isActive: boolean | undefined | null;
    username: string;
    terminated: string;
    edit: {id: number | string , role: string }
    key: React.Key;
}

const UsersTable = ({
                        data = [], total = 0, isLoading, isFetching, setSortedInfo,
                        sortedInfo, setSkip ,refetch ,onChange, pagination
                    }: { data: Array<any> | undefined, total: number | undefined, isLoading: boolean | undefined, isFetching: any, setSortedInfo(sortedInfo: any): void, sortedInfo: any, setSkip(skip: any): void, refetch():void ,onChange(current: any): void, pagination:boolean|any}) => {
    const { theme } = useSelector((state: any) => state.global);

    const location = useLocation();
    let dispatcher = location.pathname === '/user/dispatcher'

    const columns: object[] = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width:65

        },
        // {
        //     title: 'Logo',
        //     dataIndex: 'img',
        //     key: 'img',
        //     width:50,
        //     render: (val :{whichservice: string | null}) => (
        //         <>
        //             {val.whichservice && <Tooltip title={val.whichservice === 'tteld' ? 'TT ELD' : 'EVO ELD'} placement="top">
        //                 <Avatar shape="square" size='small'
        //                         src={val.whichservice === 'tteld' ? (theme === 'light' ? LogoDark : LogoLight) : EVO}/>
        //             </Tooltip>}
        //         </>
        //
        //   ),
        // },
        {
            title: 'Full name',
            dataIndex: 'name',
            key: 'name',
            render: (val: { name: string, id: string | number }) => (
                <Link to={`/user/users/${val.id}`}> {val?.name}</Link>
            )
        },
        {
            title: dispatcher ? 'Logbook' : 'Company',
            dataIndex: dispatcher ?  'logbook' :'company',
            key: dispatcher ?  'logbook' :'company',
            render: (val: { name: string, companyId: string | number }) => (
                <>
                    { dispatcher ?
                        <> {val.name}</> : <>{val.name ? <Link to={`/user/companies/${val.companyId}`}>{val?.name}</Link> : <div>Company:</div>}
                    </>}
                </>
            )
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: 130
        },
        {
            title: 'Is Active',
            dataIndex: 'isActive',
            key: 'isActive',
            width: 120,
            render: (tag: boolean) => (
                <Tag color={tag ? 'geekblue' : 'red'}>
                    {tag ? 'True' : 'False'}
                </Tag>
            ),

            sorter: (a: any, b: any) => a.isActive.length - b.isActive.length,
            sortOrder: sortedInfo.columnKey === "isActive" && sortedInfo.order,
            sortDirections: ['false', 'true', 'null'],
            // onHeaderCell: () => ({
            //     onClick: () => setSort(sort === 'ascend' ? 'descend' :
            //         'ascend'),
            //     sortDirections: ['ascend', 'descend', 'ascend'],
            // }),
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Terminated At',
            dataIndex: 'terminated',
            key: 'terminated',
        },
        {
            title: 'Actions',
            dataIndex: 'edit',
            key: 'edit',
            fixed:'right',
            width: 150,
            render: (val :{id: number | string, role: string} ) =>
                <Space>
                    <Link to={`/user/users/${val.id}`}><Button>Edit</Button></Link>
                    {/*{ val.role === 'dispatcher' &&*/}
                    {/*    <div style={{color: '#00c5ff', cursor: 'pointer'}} onClick={() => {*/}
                    {/*        Modal.confirm({*/}
                    {/*                title: 'LoadController',*/}
                    {/*                icon: <ExclamationCircleOutlined/>,*/}
                    {/*                content: 'Do you want to delete this user ?',*/}
                    {/*                okText: 'Ok',*/}
                    {/*                cancelText: 'cancel',*/}
                    {/*                onOk: async () => {*/}
                    {/*                    users.deleteUser(val.id)*/}
                    {/*                    refetch()*/}
                    {/*                }*/}
                    {/*            }*/}
                    {/*        );*/}
                    {/*    }*/}
                    {/*    }*/}
                    {/*    >*/}
                    {/*        Delete*/}
                    {/*    </div>*/}
                    {/*}*/}
                </Space>

        },
    ];

    const handleChange = (query: any, filters: any, sorter: any) => {
        const {order, field} = sorter;
        setSortedInfo({columnKey: field, order});
    };
    return (
        <div>
            <Spin size="large" spinning={isLoading || isFetching}>
                <Table style={{margin:'15px 0 0 0'}}  onChange={onChange} dataSource={data?.map((u: any, index: number): userDataSource => {
                    let create = u?.terminated;
                    const obj: userDataSource = {
                        id: index + 1,
                        img: {whichservice: u?.whichservice} ,
                        name: {name: u?.first_name + ' ' + u?.second_name, id: u.id},
                        company: {name: u?.company, companyId: u.companyId},
                        logbook: {name: u?.logbook?.name, id: u.logbook?.id},
                        role: u.role.toUpperCase(),
                        isActive: u.is_active,
                        username: u?.username,
                        terminated: create ? moment(create).format("YYYY-MM-DD, h:mm:ss a") : "",
                        edit: {id : u.id, role : u.role},
                        key: u.id
                    }
                    return obj;
                })} columns={columns}
                       pagination={pagination}
                       scroll={{x: 1000}}
                       sticky
                />
            </Spin>
        </div>
    )
};

export default UsersTable;
