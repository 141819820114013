import React from 'react';
import { Button, Col, Form as FormAnt, Input, Row, Space } from 'antd';
import DeviceVersionTable from './DeviceVersionTable';
import { deviceVersion } from '../../../../../API/TT_ELD/Team/deviceVersion';
import { useDevicesVersion } from '../../../../../Hooks/TT_ELD/Team/DeviceVersion';

type Data = {
  data?: {
    data: Array<any>;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: any;
};

const DeviceVersion = () => {
  const [form] = FormAnt.useForm();
  const { data, isLoading, isFetching, refetch }: Data = useDevicesVersion();
  const onSubmit = async (values: any) => {
    await deviceVersion.deviceVersionPost(values);
    refetch();
  };
  return (
    <div className="mainPage">
      <Space direction="vertical" size="middle" style={{ display: 'flex', marginRight: '10px' }}>
        <FormAnt form={form} layout="vertical" name="form_in_modal" onFinish={onSubmit}>
          <Row gutter={[16, 10]}>
            <Col span={4}>
              <FormAnt.Item
                label="Device Version"
                name="versioncode"
                rules={[{ required: true, message: 'Please enter your device version!' }]}
              >
                <Input placeholder="Enter your number" allowClear />
              </FormAnt.Item>
            </Col>
            <div style={{ marginTop: '30px' }}>
              <FormAnt.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </FormAnt.Item>
            </div>
            <Button
              style={{ marginLeft: 'auto', marginTop: '30px' }}
              size={'large'}
              onClick={refetch}
            >
              Refresh
            </Button>
          </Row>
        </FormAnt>
      </Space>
      <DeviceVersionTable
        data={data?.data}
        total={data?.count}
        isLoading={isLoading}
        isFetching={isFetching}
        refetch={refetch}
      />
    </div>
  );
};

export default DeviceVersion;
