import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Button, Col, Form as FormAnt, Input, Modal, Select, Space, Switch } from 'antd';
import { useFindOneOrMany, useVehicleSearch } from '../../../../../../Hooks/TT_ELD/Logs';
import { logs } from '../../../../../../API/TT_ELD/logs';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const BulkEditModal = ({
  recordStatus,
  getKey,
  data2,
  refetch,
  open,
  setOpen,
  companyId,
  todayDate,
}: {
  recordStatus: number | string;
  getKey: any;
  data2: any;
  refetch: () => void;
  open: boolean;
  setOpen(open: boolean): void;
  companyId: number | string;
  todayDate: string;
}) => {
  const vehicleData: any = useVehicleSearch('', companyId);
  const [vinNumber, setVinNumber] = useState<any>();

  const handleCancel = () => {
    setOpen(!open);
  };
  let lastKey = getKey[getKey?.length - 1];
  const { data }: any = useFindOneOrMany(
    open ? lastKey : undefined,
    companyId,
    recordStatus != 0 ? 'log' : 'logzeros',
  );
  return (
    <>
      <Modal title="Bulk Edit" open={open} footer="" onCancel={handleCancel}>
        {data2 && data && (
          <Form
            onSubmit={async (data) => {
              await logs.updateMany(
                {
                  logIds: getKey,
                  logsData: {
                    inspection: data.inspection,
                    vehicleId: data.vehicleId,
                    record_status: data.record_status,
                    ...(vinNumber !== null && { vin_number: vinNumber }),
                  },
                },
                data2?.id,
                todayDate,
                data2?.companyId,
                recordStatus,
              );
              setOpen(!open);
              refetch();
            }}
            initialValues={data ? data : undefined}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Col span={16}>
                    <div>
                      <label>Vehicle: </label>
                      <Field
                        name="vehicleId"
                        render={({ input }: { input: any }) => {
                          return (
                            <Select
                              defaultValue={data2?.vehicle?.truck_number || ''}
                              showSearch
                              placeholder="Select a Vehicle"
                              optionFilterProp="children"
                              onChange={(value, option: any) => {
                                input.onChange(value);
                                setVinNumber(option?.vin);
                              }}
                              style={{ width: 300 }}
                              filterOption={(input, option: any) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={vehicleData?.data?.data?.map((option: any) => ({
                                value: option.id,
                                label: option.truck_number,
                                vin: option.vin,
                              }))}
                            />
                          );
                        }}
                      />
                    </div>
                  </Col>
                  <Col span={16}>
                    <div>
                      <label>Inspection: </label>
                      <Field
                        name="inspection"
                        render={({ input, meta }: { input: any; meta: any }) => (
                          <Switch defaultChecked={input.value} onChange={input.onChange} />
                        )}
                      />
                    </div>
                  </Col>
                  <Col span={16}>
                    <div>
                      <label>Record status: </label>
                      <Field
                        name="record_status"
                        render={({ input }: { input: any }) => (
                          <Input style={{ width: '54%' }} type="text" {...input} />
                        )}
                      />
                    </div>
                  </Col>
                  <Col span={16}>
                    <div>
                      <label>VIN number: </label>
                      <Field
                        initialValue={vinNumber}
                        name="vin"
                        render={({ input }: { input: any }) => (
                          <Input
                            disabled={true}
                            style={{ width: '54%' }}
                            value={vinNumber}
                            type="text"
                            {...input}
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Space>
                    <Col span={16}>
                      <div className="buttons" style={{ marginTop: '20px' }}>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </div>
                    </Col>
                    <Col span={16}>
                      <div className="buttons" style={{ marginTop: '20px' }}>
                        <Button
                          type="primary"
                          danger
                          onClick={() => {
                            Modal.confirm({
                              title: 'Logs',
                              icon: <ExclamationCircleOutlined rev={undefined} />,
                              content: 'Do you want to delete this logs ?',
                              okText: 'Ok',
                              cancelText: 'cancel',
                              onOk: async () => {
                                await logs.updateMany(
                                  {
                                    logIds: getKey,
                                    logsData: {
                                      record_status: 2,
                                    },
                                  },
                                  data2?.id,
                                  todayDate,
                                  data2?.companyId,
                                  recordStatus,
                                );
                                // await logs.updateLogsMany({record_status: 2,}, getKey, recordStatus !== '0' ? 'log' : 'logzeros');
                                refetch();
                                handleCancel();
                              },
                            });
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </Col>
                  </Space>
                </Space>
              </form>
            )}
          ></Form>
        )}
      </Modal>
    </>
  );
};

export default BulkEditModal;
