import React from 'react';
import { Spin, Table } from 'antd';
import moment from 'moment/moment';

type numStr = number | string;

interface supportSource {
  no: numStr;
  company: numStr;
  status: string;
  user: string;
  createdAt: string;
  note: string;
  key: React.Key;
}

const columns: object[] = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
  },
  {
    title: 'Username',
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
  },
];

const SupportHistory = ({
  support = [],
  total = 0,
  onChange,
  isLoading,
  isFetching,
}: {
  support: any | undefined;
  total: number | undefined;
  onChange(current: any): void;
  isLoading: boolean;
  isFetching: any;
}) => {
  return (
    <div>
      <Spin size="large" spinning={isLoading || isFetching}>
        <Table
          onChange={onChange}
          dataSource={support?.map((u: any, i: number): supportSource => {
            let create = u.createdAt;
            const obj: supportSource = {
              no: i + 1,
              company: u?.company?.name,
              status: u.status,
              user: `${u.user?.first_name}  ${u.user?.second_name}`,
              createdAt: create ? moment(create).format('YYYY-MM-DD, h:mm:ss a') : '',
              note: u.note,
              key: u.id,
            };
            return obj;
          })}
          columns={columns}
          pagination={{ total: total, pageSizeOptions: [100] }}
        />
      </Spin>
    </div>
  );
};

export default SupportHistory;
