import React from 'react';
import { Button, Modal, Space, Spin, Table, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { brokerController } from '../../../../../API/Tms/brokerController';
import { useQueryClient } from 'react-query';
import { useBrokerData } from '../../../../../Hooks/cleverTms/BrokerController';

type numStr = number | string;

interface cotrollerSource {
  no: numStr;
  name: string;
  companyId: number;
  city: string;
  state: string;
  isActive: boolean;
  address: string;
  zipCodes: string;
  action: { id: numStr; queryClient: any };
  key: React.Key;
}
const columns: object[] = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    sorter: {
      compare: (a: any, b: any) => a.no - b.no,
      multiple: 3,
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    // onFilter: (value:any, record:any) => record.name.indexOf(value) === 0,
    sorter: (a: any, b: any) => a.name.length - b.name.length,
  },
  {
    title: 'Company ID',
    dataIndex: 'companyId',
    key: 'companyId',
    sorter: {
      compare: (a: any, b: any) => a.companyId - b.companyId,
      multiple: 3,
    },
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Is Active',
    dataIndex: 'isActive',
    key: 'isActive',
    render: (tag: boolean) => <Tag color={tag ? 'geekblue' : 'red'}>{tag ? 'True' : 'False'}</Tag>,
    filters: [
      {
        text: 'True',
        value: true,
      },
      {
        text: 'False',
        value: false,
      },
    ],
    onFilter: (value: any, record: any) => {
      return record.isActive === value;
    },
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Zip codes',
    dataIndex: 'zipCodes',
    key: 'zipCodes',
    sorter: {
      compare: (a: any, b: any) => a.zipCodes - b.zipCodes,
      multiple: 3,
    },
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    render: ({ id, queryClient }: { id: string | number; queryClient: any }) => (
      <Space>
        <Link to={`${id}`}>
          <Button>Edit</Button>
        </Link>
        <Button
          type="primary"
          danger
          onClick={() => {
            Modal.confirm({
              title: 'Broker',
              icon: <ExclamationCircleOutlined rev={undefined} />,
              content: 'Do you want to delete this broker ?',
              okText: 'Ok',
              cancelText: 'cancel',
              onOk: async () => {
                await brokerController.deleteBrokerController(id);
                queryClient.refetchQueries(useBrokerData);
              },
            });
          }}
        >
          Delete
        </Button>
      </Space>
    ),
  },
];

const BrokerControllerTable = ({
  controller = [],
  total = 0,
  onChange,
  isLoading = true,
}: {
  controller: Array<any> | undefined;
  total: number | undefined;
  onChange(current: any): void;
  isLoading: boolean | undefined;
}) => {
  const queryClient = useQueryClient();

  return (
    <div>
      <Spin size="large" spinning={isLoading}>
        <Table
          style={{ margin: '15px 0 0 0' }}
          onChange={onChange}
          dataSource={controller?.map((u: any, i: number): cotrollerSource => {
            const obj: cotrollerSource = {
              no: i + 1,
              name: u.name,
              companyId: u.companyId,
              city: u.city,
              state: u.state,
              isActive: u.isActive,
              address: u.address,
              zipCodes: u.zipCodes,
              action: { id: u.id, queryClient },
              key: u.id,
            };
            return obj;
          })}
          columns={columns}
          pagination={{ total: total, pageSizeOptions: [10, 50, 100] }}
        />
      </Spin>
    </div>
  );
};

export default BrokerControllerTable;
