export enum ErrorType {
  odometer = 'odometer',
  engineHours = 'engine_hours',
  intermediate = 'intermediate',
  certify = 'certify',
  duplication = 'duplication',
  driving = 'driving',
  coordinates = 'coordinates',
  location = 'location',
  vehicle = 'vehicle',
  power = 'power',
  status = 'status',
}
export enum ErrorLevel {
  warning = 'warning',
  danger = 'danger',
}
export enum SpecificError {
  odometerJump = 'odometerJump',
}
export interface TTError {
  // log: Log,
  text: string;
  type: ErrorType;
  level: ErrorLevel;
  time?: string;
  errType?: SpecificError;
  // pre?: Log,
  driver?: object | undefined;
}

export default {
  CODE_OFF_DUTY: 1,
  CODE_SLEEPER: 2,
  CODE_ON_DUTY: 3,
  CODE_DRIVING: 4,
  CODE_INTERMEDIATE_CONVENTIONAL: 1,
  CODE_INTERMEDIATE_REDUCED: 2,
  CODE_PC: 1,
  CODE_YARD: 2,
  CODE_CLEARED: 0,
  CODE_MALFUNCTION_LOGGED: 1,
  CODE_MALFUNCTION_CLEARED: 2,
  CODE_DIAGNOSTIC_LOGGED: 3,
  CODE_DIAGNOSTIC_CLEARED: 4,
  CODE_POWER_COMPLIANCE_MALFUNCTION: 'P',
  CODE_POWER_DATA_DIAGNOSTIC: '1',
  CODE_POWER_UP_CONVENTIONAL: 1,
  CODE_POWER_UP_REDUCED: 2,
  CODE_SHUT_DOWN_CONVENTIONAL: 3,
  CODE_SHUT_DOWN_REDUCED: 4,
  TYPE_DUTY: 1,
  TYPE_INTERMEDIATE: 2,
  TYPE_INDICATE: 3,
  TYPE_CERTIFICATION: 4,
  TYPE_AUTHENTICATED: 5,
  TYPE_POWER: 6,
  TYPE_MALFUNCTION: 7,
  RECORD_STATUS_CRON_MALFUNCTION: 70,
};
