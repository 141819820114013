import React, { useState } from 'react';
import { Button, Modal, Space, Spin, Table } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { approval } from '../../../../../API/TT_ELD/approval';
import ApprovalAllInfo from './Approval_AllInfo';
import { sendEvent } from '../../../../../Utils/analytics';
import moment from 'moment';
import { calcOrder } from '../../../../components/core/calcOrder';

const { confirm } = Modal;

type numStr = string | number;

interface MyData {
  additionalData: { usdot: numStr; company_name: numStr };
  first_name: numStr;
  second_name: numStr;
  email: numStr;
  createdAt: numStr;
  emailVerified: numStr;
  is_active: boolean;
  phone: numStr;
  registerStatus: numStr;
  role: numStr;
  id: numStr;
}

interface approvalSource {
  no: numStr;
  id: numStr;
  full_name: string;
  first_name: string;
  second_name: string;
  email: string;
  emailVerified: string;
  registerStatus: string;
  phone: numStr;
  companyName: numStr;
  role: numStr;
  additionalData: { company_name: numStr; usdot: numStr };
  number_employees: numStr;
  usdot: numStr;
  createdAt: numStr;
  is_active: boolean;
  view: { id: numStr };
  action: { id: numStr };
  key: React.Key;
}

const ApprovalTable = ({
  data = [],
  onChange,
  total = 0,
  skip,
  isLoading,
  isFetching,
  refetch,
}: {
  data: Array<any>;
  onChange(current: any): void;
  total: number | undefined;
  skip: number;
  isLoading: any;
  isFetching: any;
  refetch(): void;
}) => {
  const userCount = localStorage.getItem('user_count');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [getOneData, setGetOneData] = useState<MyData>();
  const handleCancel = (record: any) => {
    setGetOneData(record);
  };

  const columns: object[] = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      sorter: {
        compare: (a: any, b: any) => a.no - b.no,
        multiple: 1,
      },
    },
    {
      title: 'Full name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone number',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Company ',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'Number of employees',
      dataIndex: 'number_employees',
      key: 'number_employees',
    },
    {
      title: 'USDOT',
      dataIndex: 'usdot',
      key: 'usdot',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },

    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: ({ id }: { id: string | number }) => {
        const showConfirm = () => {
          confirm({
            title: 'Approval',
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: 'Do you want to  approval this user ?',
            onOk: async () => {
              return new Promise(async (resolve, reject) => {
                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                localStorage.setItem('user_count', `${Number(userCount) - 1}`);
                await approval.approvalPatch(id, { registerStatus: 'accepted' });
                sendEvent('Approval', 'User accept', '', 1);
                refetch();
              }).catch(() => {
                refetch();
              });
            },
            onCancel() {},
          });
        };
        const showDeleteConfirm = () => {
          confirm({
            title: 'Approval',
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: 'Do you want to reject this user  ?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
              return new Promise(async (resolve, reject) => {
                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                localStorage.setItem('user_count', `${Number(userCount) - 1}`);
                await approval.approvalPatch(id, { registerStatus: 'rejected' });
                refetch();
              }).catch(() => refetch());
            },
            onCancel() {
              console.log('Cancel');
            },
          });
        };

        return (
          <Space>
            <>
              <Button onClick={() => setIsModalVisible(true)}>View</Button>
              <Button type="primary" onClick={showConfirm}>
                Accept
              </Button>
              <Button title="shutdown time" type="primary" onClick={showDeleteConfirm} danger>
                Reject
              </Button>
            </>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Spin size="large" spinning={isLoading || isFetching}>
        <ApprovalAllInfo
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          data={getOneData}
          text={'Account Approval Information'}
        />
        <Table
          onRow={(record, id) => {
            return {
              onClick: () => {
                handleCancel(record);
              },
            };
          }}
          onChange={onChange}
          dataSource={data?.map((u: any, i: number): approvalSource => {
            const obj: approvalSource = {
              no: calcOrder(skip, 10, i),
              id: u.id,
              full_name: u?.first_name + ' ' + u?.second_name,
              first_name: u?.first_name,
              second_name: u.second_name,
              email: u.email,
              emailVerified: u?.emailVerified,
              registerStatus: u.registerStatus,
              role: u.role,
              phone: u.phone,
              companyName: u?.additionalData?.company_name,
              additionalData: {
                company_name: u.additionalData?.company_name,
                usdot: u.additionalData?.usdot,
              },
              number_employees: u.additionalData?.number_employees,
              usdot: u.additionalData?.usdot,
              createdAt: moment(u?.createdAt).format('YYYY-MM-DD HH:mm'),
              is_active: u?.is_active,
              view: { id: u.id },
              action: { id: u.id },
              key: u.id,
            };
            return obj;
          })}
          columns={columns}
          pagination={{ total: total, showSizeChanger: false, current: skip }}
        />
      </Spin>
    </div>
  );
};

export default ApprovalTable;
