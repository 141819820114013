import instanceTMS from '../apiClevertms';
import { message } from 'antd';

type numStr = string | number;

interface Field {
  id: numStr;
  fullName: numStr;
  updatedAt: numStr;
  createdAt: numStr;
  isActive: boolean;
  phone: numStr;
  email: numStr;
  companyId: numStr;
  brokerCompanyId: numStr;
  customerId: numStr;
}

export const contactController = {
  async read(id: number | string, companyId: undefined | number | string) {
    let query: any = {
      // "limit": 100,
      where: {
        and: [
          companyId
            ? {
                companyId,
              }
            : {},
          { fullName: { ilike: `%${id}%` } },
        ],
      },
    };
    const { data }: { data: object } = await instanceTMS(
      `admin/contacts?filter=${encodeURIComponent(JSON.stringify({ ...query }))}`,
    );
    const getCount = async () => {
      return 0;
    };
    const count = await getCount();

    return { data, count: count };
  },

  async contactCompanyOne(Id: string | number | undefined) {
    const { data }: { data: any } = await instanceTMS(`admin/contacts/${Id}`);
    return data;
  },

  async contactPatch(companyData: Field) {
    const { id, ...rest } = companyData;
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });
    const { data }: { data: any } = await instanceTMS(`admin/contacts/${id}`, {
      method: 'PATCH',
      data: rest,
    }).then((u) => {
      setTimeout(() => {
        message.success({ content: 'Loaded!', key, duration: 2 });
      }, 1000);
      return u;
    });
    return data;
  },

  async addContactController(companyId: any) {
    message.loading({ content: 'Loading...', key: companyId });
    const { data } = await instanceTMS('/admin/contacts/', {
      method: 'POST',
      data: {
        ...companyId,
      },
    }).then((u) => {
      setTimeout(() => {
        message.success({ content: 'Loaded!', key: companyId, duration: 2 });
      }, 1000);
      return u;
    });
    return data;
  },
  async deleteContact(id: number | string) {
    message.loading({ content: 'Loading...', key: id });
    let res;
    let error = '';
    try {
      const { data } = await instanceTMS(`/admin/contacts/${id}`, {
        method: 'DELETE',
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Deleted!', key: id, duration: 2 });
        }, 1000);
        return u;
      });
      res = data;
    } catch (err) {
      error = 'Oops something went wrong!';
    }
    return { data: res, error };
  },
  async companyFinderId(fullName: any) {
    let query: any = {
      limit: 100,
      where: {
        and: [{ fullName: { ilike: `%${fullName}%` } }],
      },
    };
    const { data }: { data: Array<any> } = await instanceTMS(
      `admin/contacts?filter=${encodeURIComponent(JSON.stringify({ ...query }))}`,
    );
    return data;
  },
};
