import React, { useCallback, useState } from 'react';
import SearchOptions from '../../../../Utils/SearchOptions';
import { SearchResultForFindDriver } from '../../../../Utils/SearchResults';
import LogsTable from './LogsTable/LogsTable';
import { useLogsData } from '../../../../Hooks/TT_ELD/Logs';
import { Button, DatePicker, Select } from 'antd';
import moment from 'moment/moment';
import { logs } from '../../../../API/TT_ELD/logs';
import dayjs from 'dayjs';

const { Option } = Select;
type Data = {
  data?: {
    data: Array<any>;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: boolean;
};
const Logs = () => {
  const [recordStatus, setRecordStatus] = useState<number>(1);
  const [todayDate, setTodayDate] = useState<string>(
    moment.utc(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').format('DD-MM-YYYY'),
  );
  const [driverId, setDriverId] = useState<undefined | string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = useState(false);

  const { data, refetch, isLoading, isFetching }: Data = useLogsData(
    driverId,
    todayDate,
    recordStatus,
  );

  const onSelectDriver = (value: any, { valId }: { valId: string | undefined }) => {
    setDriverId(valId);
  };

  const changeDate = useCallback((date: string) => {
    setTodayDate(date);
  }, []);
  const datePicker = (date: any, dateString: any) => {
    changeDate(moment.utc(dateString, 'DD-MM-YYYY').format('DD-MM-YYYY'));
  };

  const onSubmit = async (value: any) => {
    await logs.cleanCache(driverId, todayDate);
    refetch();
  };
  const defaultValue: any = dayjs();

  return (
    <div className="mainPage">
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <SearchOptions
          SearchResult={(query: string) => SearchResultForFindDriver(query)}
          onSelect={onSelectDriver}
          placeholder="Driver name"
          value={''}
        />

        <DatePicker
          disabled={!driverId}
          defaultValue={defaultValue}
          format={'DD-MM-YYYY'}
          disabledDate={(current) => {
            let customDate = moment().add(1, 'd').format('DD-MM-YYYY');
            return current && current > moment(customDate, 'DD-MM-YYYY');
          }}
          onChange={datePicker}
          style={{ width: 200 }}
          clearIcon
        />
        <Select
          popupMatchSelectWidth
          disabled={!driverId}
          defaultValue={1}
          onChange={(value: number, option) => {
            setRecordStatus(value);
          }}
          style={{ width: 200 }}
        >
          <Option key={0}>RECORD_STATUS=0</Option>
          <Option key={1}>RECORD_STATUS=1</Option>
          <Option key={2}>RECORD_STATUS=2</Option>
          <Option key={3}>RECORD_STATUS=3</Option>
          <Option key={4}>RECORD_STATUS=5</Option>
          <Option key={5}>RECORD_STATUS=20</Option>
          <Option key={6}>RECORD_STATUS=40</Option>
          <Option key={7}>RECORD_STATUS=70</Option>
        </Select>
        <Button
          disabled={!driverId}
          onClick={onSubmit}
          type="primary"
          size={'large'}
          style={{
            marginLeft: 'auto',
            marginRight: '15px',
          }}
        >
          Clean Cashe
        </Button>
        <Button size={'large'} onClick={refetch}>
          Refresh
        </Button>

        {isModalVisible && (
          <Button
            style={{ marginLeft: '15px' }}
            key="1"
            type="primary"
            size="large"
            onClick={() => setOpen(!open)}
          >
            Bulk Edit
          </Button>
        )}
      </span>
      <LogsTable
        logs_data={data}
        isLoading={isLoading}
        userId={driverId}
        recordStatus={recordStatus}
        isFetching={isFetching}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
        setIsModalVisible={setIsModalVisible}
        todayDate={todayDate}
      />
    </div>
  );
};

export default Logs;
