import instanceTMS from '../apiClevertms';
import { message } from 'antd';

interface Field {
  id: string | number;
  zipCode: boolean;
  city: boolean;
  state: boolean;
  geopoint: boolean;
  lat: number;
  lng: number;
  isActive: boolean;
  timezone: boolean;
  updatedAt: boolean;
  createdAt: boolean;
}

export const zipCodeController = {
  async read(id: any) {
    let query: any = {
      limit: 100,
      where: {
        and: [
          { id: { ilike: `%${id}%` } },
          // {"companyId":{"ilike":`3`}},
        ],
      },
    };
    const { data }: { data: object } = await instanceTMS(
      `admin/zip-codes?filter=${encodeURIComponent(JSON.stringify({ ...query }))}`,
    );
    const getCount = async () => {
      return 0;
    };
    const count = await getCount();

    return { data, count: count };
  },

  async zipCodeCompanyOne(companyId: string | number | undefined) {
    const { data }: { data: any } = await instanceTMS(`admin/zip-codes/${companyId}`);
    return data;
  },

  async zipCodePatch(companyData: Field) {
    const { id, ...rest } = companyData;
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });
    const { data }: { data: any } = await instanceTMS(`admin/zip-codes/${id}`, {
      method: 'PATCH',
      data: rest,
    }).then((u) => {
      setTimeout(() => {
        message.success({ content: 'Loaded!', key, duration: 2 });
      }, 1000);
      return u;
    });
    return data;
  },

  async addZipCodeController(companyId: any) {
    message.loading({ content: 'Loading...', key: companyId });
    const { data } = await instanceTMS('/admin/zip-codes/', {
      method: 'POST',
      data: {
        ...companyId,
      },
    }).then((u) => {
      setTimeout(() => {
        message.success({ content: 'Loaded!', key: companyId, duration: 2 });
      }, 1000);
      return u;
    });
    return data;
  },
  async deleteZipCodeController(id: number | string) {
    message.loading({ content: 'Loading...', key: id });
    let res;
    let error = '';
    try {
      const { data } = await instanceTMS(`/admin/zip-codes/${id}`, {
        method: 'DELETE',
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Deleted!', key: id, duration: 2 });
        }, 1000);
        return u;
      });
      res = data;
    } catch (err) {
      error = 'Oops something went wrong!';
    }
    return { data: res, error };
  },
  async zipFinderId(zip: any) {
    let query: any = {
      limit: 100,
      where: {
        and: [{ zip: { ilike: `%${zip}%` } }],
      },
    };
    const { data }: { data: Array<any> } = await instanceTMS(
      `admin/zip-codes?filter=${encodeURIComponent(JSON.stringify({ ...query }))}`,
    );
    return data;
  },
};
