import React from 'react';
import { Button, Space, Spin, Table } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { calcOrder } from '../../../../../components/core/calcOrder';

type numStr = number | string;

interface supportSource {
  no: numStr;
  company: numStr;
  companyId: number;
  status: string;
  user: string;
  updatedAt: string;
  time: string;
  note: string;
  info: string;
  key: React.Key;
}

const columns: object[] = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    width: 70,
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
    width: 190,
  },
  {
    title: 'Company ID',
    dataIndex: 'companyId',
    key: 'companyId',
    width: 106,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 80,
  },
  {
    title: 'Username',
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: 'Updated time',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: 200,
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    width: 120,
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: 100,
  },
  {
    title: 'Info',
    dataIndex: 'info',
    key: 'info',
    render: (companyId: string | number) => (
      <Space>
        <Link to={`${companyId}`}>
          <Button>Info</Button>
        </Link>
      </Space>
    ),
    fixed: 'right',
    width: 100,
  },
];

const SupportTable = ({
  support = [],
  total = 0,
  onChange,
  isLoading,
  isFetching,
  skip,
}: {
  support: Array<any> | undefined;
  total: number | undefined;
  onChange(current: any): void;
  isLoading: any;
  isFetching: any;
  skip: number;
}) => {
  return (
    <div>
      <Spin size="large" spinning={isLoading || isFetching}>
        <Table
          size="small"
          style={{ boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px' }}
          onChange={onChange}
          dataSource={support?.map((u: any, i: number): supportSource => {
            let create = u.updatedAt;
            const obj: supportSource = {
              no: calcOrder(skip, 100, i),
              company: u?.company?.name,
              companyId: u?.companyId,
              status: u.status,
              user: `${u.user?.first_name}  ${u.user?.second_name}`,
              updatedAt: create ? moment(create).format('YYYY-MM-DD, h:mm:ss a') : '',
              time: create ? moment(create).fromNow() : '',
              note: u.note,
              info: u.companyId,
              key: u.id,
            };
            return obj;
          })}
          columns={columns}
          pagination={{ total: total, pageSize: 100, showSizeChanger: false }}
          scroll={{ y: 640, x: 1000 }}
          sticky
        />
      </Spin>
    </div>
  );
};

export default SupportTable;
