import { Button, Card, Form as FormAnt, InputNumber, Spin } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { companies } from '../../../../../../../API/TT_ELD/Users/company';
import { useCompanyData } from '../../../../../../../Hooks/TT_ELD/Companies';
import Notfound from '../../../../../../../Utils/Notfound';

type params = {
  readonly id: any;
};
type MyObjectType = {
  [key: string | number]: any;
};
const SubcriptionQuantity = () => {
  const { id } = useParams<params>();
  const { data, status, refetch }: MyObjectType = useCompanyData(id);

  const [loadings, setLoadings] = useState<boolean[]>([]);
  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };
  const onSubmit = async (value: any) => {
    value = {
      ...value,
      companyId: id,
    };
    companies.updateSubscriptionData(value);
    refetch();
  };

  return (
    <div className="mainPage">
      <Card>
        {status === 'loading' ? (
          <Spin size="large" spinning={!data} />
        ) : data ? (
          <FormAnt
            name="basic"
            layout="vertical"
            wrapperCol={{ span: 16 }}
            initialValues={{
              ...data,
              quantity: data?.stripe.subscription?.quantity,
            }}
            onFinish={onSubmit}
            autoComplete="off"
          >
            <FormAnt.Item
              wrapperCol={{ span: 6 }}
              label="Quantity"
              name="quantity"
              style={{ width: '30%' }}
              rules={[
                { required: true },
                { type: 'number', warningOnly: true },
                { type: 'number', min: 0 },
              ]}
            >
              <InputNumber placeholder="quantity" style={{ width: '100%' }} />
            </FormAnt.Item>

            <FormAnt.Item>
              <Button
                onClick={() => enterLoading(1)}
                loading={loadings[1]}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </FormAnt.Item>
          </FormAnt>
        ) : (
          <Notfound />
        )}
      </Card>
    </div>
  );
};

export default SubcriptionQuantity;
