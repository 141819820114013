import React, { useState } from 'react';
import SearchInput from '../../../../../Utils/SearchInput';
import { Button } from 'antd';
import { useApprovalCount, useRejected } from '../../../../../Hooks/TT_ELD/Approval';
import RejectedListTable from './RejectedListTable';
import useRouteQuery from '../../../../../Utils/useRouterQuery';

const RejectedList = () => {
  const [skip, setSkip] = useState<number>(1);

  const [userName, setUserName] = useRouteQuery('', 'userName', (v) => String(v));
  const [userEmail, setUserEmail] = useRouteQuery('', 'userEmail', (v) => String(v));
  const [company, setCompany] = useRouteQuery('', 'company', (v) => String(v));

  const handleChange = (event: string, inputName: string) => {
    const updateFunctions: Record<string, (value: string) => void> = {
      userName: setUserName,
      userEmail: setUserEmail,
      company: setCompany,
    };

    if (updateFunctions[inputName]) {
      updateFunctions[inputName](event.trim());
      setSkip(1);
    }
  };
  type Data = {
    data?: {
      data: any;
      count: number;
    };
    isLoading?: boolean;
    refetch?: any;
    isFetching?: any;
  };
  const { data, refetch, isLoading, isFetching }: Data = useRejected(
    userName,
    userEmail,
    company,
    skip,
  );
  const count = useApprovalCount();

  const onChange = (query: any) => {
    setSkip(1 * parseInt(query.current));
  };
  const updates = () => {
    refetch();
    count.refetch();
  };
  return (
    <div className="mainPage">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <SearchInput
          value={company}
          onDebounceChange={(event: string) => handleChange(event, 'company')}
          placeholder={'Search by company'}
        />
        <SearchInput
          value={userName}
          onDebounceChange={(event: string) => handleChange(event, 'userName')}
          placeholder={'Search by name'}
        />
        <SearchInput
          value={userEmail}
          onDebounceChange={(event: string) => handleChange(event, 'userEmail')}
          placeholder={'Search by email'}
        />
        <Button style={{ marginLeft: 'auto' }} size={'large'} onClick={updates}>
          Refresh
        </Button>
      </div>

      <RejectedListTable
        data={data?.data}
        onChange={onChange}
        total={data?.count}
        isLoading={isLoading}
        isFetching={isFetching}
        refetch={updates}
        skip={skip}
      />
    </div>
  );
};

export default RejectedList;
